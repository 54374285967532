import React, { Component } from 'react';
import { Input, Container, Row, Col } from 'reactstrap';
import { Subject, timer } from 'rxjs'
import { switchMap, debounce } from 'rxjs/operators'
import { Link } from 'react-router-dom'
import { AiOutlineCloseCircle } from 'react-icons/ai';

export default class SearchReddit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            searchResults: [],
        }

        this.throttledSearch = new Subject();
        this.searchSubscription = React.createRef();
        this.searchResultsColumn = React.createRef();
        this.inputRef = React.createRef();
    }

    componentDidUpdate(prevProps){
        if(prevProps.showingTab !== 'search' && this.props.showingTab === "search"){
            this.inputRef.current.focus();
        };
    }

    formatResults = results => results?.map((value, index) => {
        const {
            display_name,
            public_description,
            subscribers
        } = value.data;

        return {
            display_name,
            public_description,
            subscribers
        }
    })

    changeSearchTerm = searchText => {
        if (searchText === "") {
            this.setState({ searchText });
            this.throttledSearch.next('');
        } else {
            this.setState({ searchText });
            this.throttledSearch.next(searchText);
        }
    }


    componentDidMount = () => {
        this.searchSubscription = this.throttledSearch
            .pipe(
                debounce(() => timer(500)),
                //sampleTime(1000),
                switchMap(async term => {
                    if (term === '') return []
                    try {
                        let results = await fetch(`https://www.reddit.com/subreddits/search.json?q=${term}`);
                        results = await results.json();
                        return results;
                    } catch (e) {
                        console.log(e);
                        return []
                    }
                })
            ).subscribe(results => {
                let newResults;
                if (results.length === 0) {
                    newResults = [];
                } else {
                    newResults = this.formatResults(results?.data?.children);
                }
                this.setState({ searchResults: newResults });
                if (this.searchResultsColumn?.current?.scrollTop)
                    this.searchResultsColumn.current.scrollTop = 0;
            })
    }

    render() {
        return <Container style={{ display: this.props.showingTab === "search" ? "block" : "none", backgroundColor: "white", padding: 5, border: "1px dashed lightgrey" }}>
            {
                <Row id="search-reddit-row" style={{ maxHeight: "calc(90vh - 100px)", overflow: "auto" }}>
                    <Col >
                        {
                            this.state.searchResults.map((value, index) => {
                                const {
                                    display_name,
                                    public_description,
                                    subscribers
                                } = value;
                                return <Link to={`/r/${display_name}`} onClick={() => this.props.setShowingTab('none')}>
                                    <div key={display_name} className="reddit-search-result-item" style={{ marginBottom: 20, clear: "both" }}>
                                        <span style={{ fontWeight: "bold" }}>{display_name}{"    "}</span>
                                        <span style={{ color: "grey" }}> {Math.floor(subscribers / 1000).toLocaleString()}k</span>
                                        <br></br>
                                        <span style={{ fontStyle: 'italic', fontSize: "14px" }}>{public_description}</span>
                                    </div>
                                </Link>
                            })
                        }
                    </Col>
                </Row>
            }
            <Row className="g-0">
                <Col style={{ flex: "0 0", marginRight: 4 }}>
                    <AiOutlineCloseCircle style={{ fontSize: 20, color: "grey" }} onClick={() => this.changeSearchTerm('')} />
                </Col>
                <Col style={{ "alignItems": "center", display: "flex" }}>
                    <Input
                        innerRef={this.inputRef}
                        style={{ lineHeight: "14px", padding: 5, fontSize: 14 }}
                        placeholder="Find a Subreddit"
                        value={this.state.searchText}
                        onChange={e => this.changeSearchTerm(e.target.value)}
                    />
                </Col>
            </Row>
        </Container>
    }
}