//@ts-check
import React from 'react';
import { applyMiddleware, createStore, compose } from 'redux';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';

import { createBrowserHistory } from 'history';
import thunk from 'redux-thunk';

import 'bootstrap/dist/css/bootstrap.css';
import './style.css';

import * as serviceWorker from './serviceWorker';
import reducers from './store/reducers';
import App from './components/App';
import Api from './controllers/Api';
import ApiRequests from './middleware/ApiRequests';
import RouteInput from './controllers/RouteInput';
import { htmlReduce } from './controllers/htmlReduce';

const history = createBrowserHistory();
const htmlReduceInstance = new htmlReduce();
const routeInput = new RouteInput( Api );
const ApiRequestsMiddleware = new ApiRequests();

serviceWorker.register()

var preloadedState = ( window.REDUX_STATE && JSON.parse ( window.REDUX_STATE )) || undefined;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducers(history),
	preloadedState,
	composeEnhancers(
		applyMiddleware(
			routerMiddleware( history ),
			routeInput.middleware,
			ApiRequestsMiddleware.middleware,
			thunk,
			htmlReduceInstance.middleware,
			//split.middleware
		)
	) 
);

ReactDOM.render (
	<Provider store = { store } >
		<ConnectedRouter history = { history } >
			<App />
		</ConnectedRouter>
	</Provider>,
	document.getElementById ( 'root' )
);

//serviceWorker.register()