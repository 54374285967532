import comments from './reducers/comments';
import loading from './reducers/loading';
import subreddit from './reducers/subreddit';
import menu from './reducers/menu';
import more from './reducers/more';

var reduce = {
    comments, 
    loading, 
    subreddit, 
    menu,
    more
};

export default reduce;