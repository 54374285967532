//@ts-check
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { MdDeleteOutline } from 'react-icons/md'
import { BsPlus } from 'react-icons/bs';
import reduce from '../store/reduce';


const VisitedSubreddits = props => {
	return (
        <Container style={{backgroundColor: "white", padding: 5, border: "1px dashed lightgrey", maxHeight: "calc(90vh - 100px)", overflow: "auto"}}>
            <Row className="g-0">
                <Col>
                    <form id = "add-menu" style={{fontSize: "14px", marginLeft: 5, marginBottom: 5}}>
                        {/*removed ref here, will need to refix todo*/}
                        <button onClick = { e => 
                            {
                                e.preventDefault();
                                props.clearAllRecentMenu();
                            }
                        } 
                        type="submit">
                            <MdDeleteOutline size={18}/>
                            Clear History
                        </button>
                    </form>			
                </Col>
            </Row>
            <Row className="g-0">
                <Col style={{}}>
                        {props.recentSubreddits.map((item, index) => {
                            return <span style={{flex: "0 1 auto", width: "100%"}} key={index + item} className="menu-item">
                                <BsPlus style={{cursor:"pointer"}} onClick={() => props.addMenu(item)} />
                                <Link style={{fontSize: 16, fontWeight: "bold"}} onClick={() => {}} className="menu-link" to={"/r/" + item}>{item}</Link>
                            </span>;
                        })}
				</Col>
			</Row>
		</Container>
	)
}

export default connect(
    ({data}) => ({
        recentSubreddits: data.menu.recentSubreddits
    }),
    (dispatch) => ({
        clearAllRecentMenu: () => dispatch(reduce.menu.clearAllRecentMenu()),
        addRecentMenuItem: (item) => dispatch(reduce.menu.addRecentMenuItem(item)),
        addMenu: item => {
            dispatch(reduce.menu.addMenu(item))
        }
    })
)(VisitedSubreddits);