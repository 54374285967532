//@ts-check
import { connect } from 'react-redux';

import Comments from '../components/Comments';
import { htmlActions } from '../controllers/htmlReduce';
import { isBrowser } from '../utility';
import reduce from '../store/reduce';
import ApiRequests from '../middleware/ApiRequests';

const mapStateToProps = state => {
	return {
		comments: state.data.comments.comments,
		linkData: state.data.comments.linkData,
		subreddit: state.data.subreddit.subreddit,
		id: state.data.comments.linkData?.id,
		isBrowser: isBrowser,
		sortBy: state.data.comments.sortBy
	}
}

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchMore: async (linkId, moreArray) => {
			await dispatch(htmlActions.fetchMore(linkId, moreArray))
			props.setShowColumn('none');
			return Promise.resolve();
		},
		changeSortBy: newSortBy => {
			dispatch(reduce.comments.changeSortBy(newSortBy));
			dispatch(ApiRequests.getComments(undefined, undefined, newSortBy));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Comments);