import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { BsChatSquareFill, BsFillShiftFill, BsArrowClockwise, BsCardImage, BsLink, BsCameraVideo } from 'react-icons/bs';
import { IoLogoReddit } from 'react-icons/io';
import { roundNumber } from '../utility';
import { BiUpvote } from 'react-icons/bi';
import { BsFillArrowRightSquareFill, BsArrowRightShort, BsFillArrowLeftSquareFill, BsArrowLeftShort } from 'react-icons/bs'
import moment from 'moment';
import { Subject, from } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators'
import api from '../controllers/Api';
import reduce from '../store/reduce';

import SearchSubreddit from '../containers/SearchSubreddit';

const sortByOptions = ["hot", "new", "rising", "top", "controversial"]

const Subreddit = (props) => {
	const prevSubredditRef = useRef();
	const searchSubject$ = useRef(new Subject());

	//add subject listener
    useEffect(() => {
        searchSubject$.current.pipe(
            switchMap(({subreddit, sortBy}) => {
                return from(
                    api.fetchSubreddit(subreddit, null, sortBy)
                ).pipe(
                    takeUntil(searchSubject$.current)
                )
            })
        ).subscribe(({subreddit, threads}) => {
			props.dispatch(reduce.subreddit.saveSubreddit({threads, subreddit}))
        })
    }, [])

	const { subreddit } = props;

	useEffect(
		() => {
			if(prevSubredditRef.current !== subreddit && prevSubredditRef.current !== undefined){
				props.resetScroll();
				//console.log("props.subreddit useeffect:", prevSubredditRef.current,  props.subreddit);
				prevSubredditRef.current = subreddit;
			} else if ( prevSubredditRef.current === undefined){
				prevSubredditRef.current = subreddit
			}
		},
		[subreddit]
	);

	function changeLink( event, subreddit, linkId, itemSubreddit, moreLink ) {
		event.stopPropagation();
		const permalink = `https://reddit.com/r/${subreddit}/comments/${linkId}`
		props.changeLink( permalink, subreddit, linkId, itemSubreddit, moreLink );
	}

	function getIcon(itemUrl){
		let Icon;
		if(itemUrl.startsWith("https://i.redd.it")){
			Icon = <BsCardImage style={{fontSize: "18px", paddingBottom: "4px", marginRight: "3px"}} />
		} else if(itemUrl.startsWith("https://v.redd.it")){
			Icon = <BsCameraVideo style={{fontSize: "18px", paddingBottom: "4px", marginRight: "3px"}} />
		} else if (itemUrl.startsWith("https://www.reddit.com")){
			Icon = <IoLogoReddit style={{fontSize: "20px", paddingBottom: "4px", marginRight: "3px"}} />
		} else {
			Icon = <BsLink style={{fontSize: "20px", paddingBottom: "4px", marginRight: "3px"}} />
		}
		return Icon
	}

	if ( props.threads && props.threads.length > 0 ) {
		let renLinks = props.threads.map (( item, index ) => {
			const arrowColor = props.hideArrows ? "#00000000" : "#80808075";
			return (
				<div key = { index } 
					onClick = { event => {
						
						changeLink ( event, props.subreddit, item.linkId, item.subreddit, item.url );
					}} 
					className = { (props.hideThumbnails ? "hide-images " : "" ) + "list-item " + ( index % 2 === 0 ? "even" : "odd" )}
				>
					<div className="thumb">
					{( item.thumbnail !== "spoiler" && item.thumbnail !== "default" && item.thumbnail !== "image" && item.thumbnail !== "self" && item.thumbnail !== "")
						? 	<img onClick={() => props.setShowColumn('none')} alt="" src={item.thumbnail} className="thumbnail" />	
						:  <img onClick={() => props.setShowColumn('none')} alt="" src="/img/thumb-preview.png" className="thumbnail" />
					}
						<span className="focus-arrow" onClick ={() => props.setShowColumn(props.linkId === item.linkId && props.showColumn !== "none" ? "none" : 'comments')}>
							{ props.showColumn === "comments" ? 
								<BsFillArrowRightSquareFill style={{ color: arrowColor, position: "absolute", top: "0px", left: "0px"}} />
								: <BsArrowRightShort style={{ color: arrowColor, position: "absolute", top: "0px", left: "0px", borderBottom: `2px dashed ${arrowColor}`, borderRight: `2px dashed ${arrowColor}`}} />
							}
						</span>
						<span className="focus-arrow" onClick ={() => props.setShowColumn(props.linkId === item.linkId && props.showColumn !== "none" ? "none" : 'details')}>
							{ props.showColumn === "details" ? 
								<BsFillArrowLeftSquareFill style={{ color: arrowColor, position: "absolute", top: "0px", right: "0px"}} />
								: <BsArrowLeftShort style={{ color: arrowColor, position: "absolute", top: "0px", right: "0px", borderBottom: `2px dashed ${arrowColor}`, borderLeft: `2px dashed ${arrowColor}`}} />
							}
						</span>
					</div>
					<div className="title">
						<span className = ".hide-thumb-focus-arrow" onClick ={props.showColumn === "comments" ? () => props.setShowColumn('none') : () => props.setShowColumn('comments')}>
							{ props.showColumn === "comments" ? 
								<BsFillArrowRightSquareFill style={{ color: "#80808075", paddingBottom: "2px", paddingRight: "3px", display: "none"}} />
								: <BsArrowRightShort style={{ color: "#80808075", paddingBottom: "2px", paddingRight: "3px", display: "none"}} />
							}
						</span>
						<span className = ".hide-thumb-focus-arrow" onClick ={props.showColumn === "details" ? () => props.setShowColumn('none') : () => props.setShowColumn('details')}>
							{ props.showColumn === "details" ? 
								<BsFillArrowLeftSquareFill style={{ color: "#80808075", paddingBottom: "2px", paddingRight: "3px", display: "none"}} />
								: <BsArrowLeftShort style={{ color: "#80808075", paddingBottom: "2px", paddingRight: "3px", display: "none"}} />
							}
						</span>
						<span className="comment-link">{item.title}</span>
						
						{/*<span className="twitter">
							<a className="twitter-link" target="_blank" 
								href={"https://twitter.com/intent/tweet?text=" 
									+ encodeURIComponent(`${item.title}`) 
									+ "&url=" 
									+ encodeURIComponent(`https://OtherIt.com/r/${this.props.subreddit}/${item.linkId}`)}>
								<img src="/img/twitter.png" alt="twitter" />
							</a>
						</span>
						<span className="facebook">
							<img src="/img/facebook.png" alt="facebook" onClick=
								{(event)=>this.facebookShare(event, `https://OtherIt.com/r/${this.props.subreddit}/${item.linkId}`)}
							/>
						</span>
						*/}
					</div>
					<div className="meta">
						<a target="_blank" rel="noreferrer" href={"https://reddit.com" + item.permalink}>{getIcon(item.url)}</a>
						<span className="score meta-item"><BsFillShiftFill style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }}/>{ roundNumber(item.score) }</span>
						<span className="comments meta-item"><BsChatSquareFill style = {{ color:"LightGrey", marginRight: "3px" }}/>{ roundNumber(item.numComments) }</span>
						<span className="subreddit meta-item"><Link onClick = { e => e.stopPropagation()} to = { "/r/" + item.subreddit } >/{ item.subreddit }</Link></span>
						<span className="domain meta-item"><a target="_blank" rel="noreferrer" href = { item.url } onClick = { e => e.stopPropagation() }>{ item.domain }</a></span>
						<span className="meta-item">{moment(item.created*1000).fromNow(true)}</span>
						<br></br>
						<a style={{color: "#949494"}} target="_blank" rel="noreferrer" href = { "https://reddit.com/user/" + item.author } onClick = { e => e.stopPropagation() }>{item.author}</a>
						{item.authorData ?
							<>
								<br></br>
								<span >{item.authorData.age}yr </span>
								<span ><BiUpvote style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }} />{item.authorData.link_karma}k-</span>
								<span >{item.authorData.comment_karma}k </span>
								<span ><BsArrowClockwise style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }} />{item.authorData.comments_per_month}/month </span>
							</>
							: null
						}	
					</div>
					<div className="clear"></div>	
				</div>
			);
		});

		return <div style={{padding: "0 5px"}}>
			<SearchSubreddit />
			<div style={{textAlign: "right", marginRight: 7, marginTop: 5, fontSize: 12, color: 'grey'}}>
				{props.actuallyShowingSearchResults ? 
					null 
					: sortByOptions.map((item, index) => <span 
						key={item}
						style={{cursor: "pointer", padding: "0px 3px 3px", fontWeight: props.sortBy === item ? "800" : "normal"}}
						onClick={()=>{
							props.changeSortBy(item)
						}}>
							{item}
						</span>
					)
				}
			</div>
			{renLinks}
			{props.actuallyShowingSearchResults ? <div style={{textAlign: "center", color: "lightgrey", marginBottom: 19}}>End of Search Results</div> : null}
		</div>;
	} else return null;
};

export default Subreddit;