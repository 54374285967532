//@ts-check
import { Subject, from, NEVER } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators'
import api from '../controllers/Api';

import reduce from '../store/reduce';

const getApiString = /^API\/([a-zA-Z]*)$/

class ApiRequests {
	subredditFetch;
	commentsFetch;
	dispatch;
	getState;

	constructor(Api) {
		this.Api = Api;
		this.subredditSource$ = new Subject();
		this.commentsSource$ = new Subject();
		this.subredditCommentsSource$ = new Subject();
	}

	middleware = store => {
		this.dispatch = store.dispatch;
		this.getState = store.getState;
		this.addListeners();

		return next => action => {
			next(action)
			if (typeof (action) !== 'function' && action.type.startsWith("API/")) {
				const actionTerm = getApiString.exec(action.type)[1];
				switch (actionTerm) {
					case 'getSubreddit':
						this.subredditSource$.next(action); //({subreddit, after, sortBy})
						break;
					case 'getComments':
						this.commentsSource$.next(action); //({subreddit, linkId, after, sortBy})
						break;
					case 'getSubredditComments':
						this.subredditCommentsSource$.next(action);
						break;
					default:
						break;
				}
			}
		}
	}

	static getSubreddit(subreddit, subredditSortBy = 'hot', needFirstComment = false, commentsSortBy) {
		return (dispatch, getState) => {
			let nextSubreddit = subreddit || getState().data.subreddit.subreddit;
			let newCommentsSortBy = commentsSortBy || getState().data.comments.commentsSortBy;
			dispatch({
				type: 'API/getSubreddit',
				action: {
					subreddit: nextSubreddit,
					subredditSortBy,
					needFirstComment,
					commentsSortBy: newCommentsSortBy
				}
			})
		}
	}

	static getComments(subreddit, linkId, commentsSortBy = 'confidence') {
		return (dispatch, getState) => {
			let nextSubreddit = subreddit || getState().data.subreddit.subreddit;
			let nextLinkId = linkId || getState().data.comments.linkData.id;
			dispatch({
				type: 'API/getComments',
				action: {
					subreddit: nextSubreddit,
					linkId: nextLinkId,
					commentsSortBy
				}
			})
		}
	}

	static getSubredditComments(subreddit, linkId, subredditSortBy = 'hot', commentsSortBy = 'confidence') {
		return (dispatch, getState) => {
			dispatch({
				type: 'API/getSubredditComments',
				action: {
					subreddit,
					linkId,
					subredditSortBy,
					commentsSortBy,
				}
			})
		}
	}

	addListeners = () => {
		this.subredditFetch = this.addSubredditListener();
		this.commentsFetch = this.addCommentsListener();
		this.subredditCommentsFetch = this.addSubredditCommentsListener();
	}

	addSubredditListener = () => {
		this.subredditSource$.pipe(
			switchMap((action) => {
				this.dispatch(reduce.loading.routeLoading(true))
				const { subreddit, subredditSortBy, needFirstComment, commentsSortBy } = action.action;
				return from(
					api.fetchSubreddit(subreddit, undefined, subredditSortBy)
						.then(({ subreddit, threads }) => {
							return ({ subreddit, threads, needFirstComment, commentsSortBy })
						})
						.catch(e => {
							throw new Error(e);
						})
				)
					.pipe(
						takeUntil(this.subredditSource$)
					)
			})
		).subscribe({
			next: ({ subreddit, threads, needFirstComment, commentsSortBy }) => {
				this.dispatch(reduce.subreddit.saveSubreddit({ threads, subreddit }));
				this.dispatch(reduce.menu.addRecentMenuItem(subreddit));
				if (needFirstComment) {
					this.dispatch(ApiRequests.getComments(subreddit, this.pickFirstComment(threads), commentsSortBy));
					this.dispatch(reduce.more.saveMore(this.getMoreFromSubreddit(threads)));
				}
				this.dispatch(reduce.loading.routeLoading(false))
			},
			//error
			error: (err) => {
				this.dispatch(reduce.loading.routeLoading(false))
				return NEVER;
			}
		})
	}

	pickFirstComment = threads => {
		return threads[0]['linkId'];
	}

	addCommentsListener = () => {
		this.commentsSource$.pipe(
			switchMap(action => {
				this.dispatch(reduce.loading.routeLoading(true))
				const { subreddit, linkId, commentsSortBy } = action.action;
				return from(
					api.fetchComments(subreddit, linkId, commentsSortBy)
						.catch(e => {
							throw new Error(e);
						})
				).pipe(
					takeUntil(this.commentsSource$)
				)
			})
		).subscribe({
			next: (results) => {
				this.dispatch(reduce.comments.saveComments(results))
				this.dispatch(reduce.loading.routeLoading(false))
			},
			error: (err) => {
				console.log("addCommentsListener error", err);
				this.dispatch(reduce.loading.routeLoading(false))
				return NEVER;
			}
		})
	}

	addSubredditCommentsListener = () => {
		this.subredditCommentsSource$.pipe(
			switchMap((action) => {
				this.dispatch(reduce.loading.routeLoading(true))
				const { subreddit, linkId, subredditSortBy, commentsSortBy } = action.action;

				//build more link from id
				const url = `https://reddit.com/r/${subreddit}/comments/${linkId}`;
				this.dispatch(reduce.more.saveMore({ more: url, moreType: "url", permalink: url }));

				return from(
					Promise.all([
						api.fetchSubreddit(subreddit, undefined, subredditSortBy)
							.then(({ subreddit, threads }) => {
								return ({ subreddit, threads })
							})
							.then(({ subreddit, threads }) => {
								this.dispatch(reduce.subreddit.saveSubreddit({ threads, subreddit }));
								this.dispatch(reduce.menu.addRecentMenuItem(subreddit));
							})
							.catch(e => {
								throw new Error(e);
							}),
						api.fetchComments(subreddit, linkId, commentsSortBy)
							.then(results => {
								this.dispatch(reduce.comments.saveComments(results))
							})
							.catch(e => {
								throw new Error(e);
							})
					])
				)
					.pipe(
						takeUntil(this.subredditSource$ || this.commentsSource$)
					)
			})
		).subscribe({
			next: () => {
				this.dispatch(reduce.loading.routeLoading(false))
			},
			//error
			error: (err) => {
				console.log("addSubredditListener error", err);
				this.dispatch(reduce.loading.routeLoading(false))
				return NEVER;
			}
		})
	}

	getMoreFromSubreddit(threads) {
		let { url: more, permalink } = threads[0];
		permalink = "https://reddit.com" + permalink;
		return { more, permalink, moreType: "url" }
	}
}

export default ApiRequests;