import { deepSetData } from '../../utility'

const comments = {
	//const sortByOptions = ["confidence", "top", "new", "controversial", "old", "random", "qa"]

	initial: {
		linkData: {},
		comments: [],
		moreComments: [],
		sortBy: 'confidence'
	},

	saveComments: ({ comments, linkData }) => (dispatch, getState) => {
		let moreComments = [];
		let newComments;

		if(linkData){
			newComments = [ ...comments];
			if(newComments.length > 1){
				const lastComment = newComments[newComments.length - 1];
				if(lastComment.more){
					moreComments = lastComment.more;
					delete(newComments[newComments.length - 1])
				}
			}
			
			const nextState = deepSetData(getState())('comments')({
				comments: newComments,
				moreComments,
				linkData,
			})
	
			dispatch({
				type: "DATA/COMMENTS/saveComments",
				nextState,
				action: {
					comments,
					linkData,
				}
			})
		}
	},
	appendComments: ({ moreComments, commentId }) => (dispatch, getState) => {
		const state = getState().data;
		
		//make sure we havne't changed comments before saving data
		if(state.comments.linkData.id === commentId){
			const newComments = state.comments.comments.concat( moreComments );

			const oldMore = state.comments.moreComments;
			const newMore = oldMore.slice(50);
	
			const nextState = { ...state, comments: { 
				...state.comments,
				comments: newComments,
				moreComments: newMore 
			}};
	
			dispatch({
				type: "DATA/COMMENTS/appendComments",
				nextState,
				action: {
					moreComments,
					commentId
				}
			})
		}
	},
	changeSortBy: newSortBy => (dispatch, getState) => {
		const nextState = deepSetData(getState())('comments')({
			sortBy: newSortBy
		})

		dispatch({
			type: "DATA/COMMENTS/ChangeSortBy",
			nextState,
			action: newSortBy
		})
	}
}

export default comments;