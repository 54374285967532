// @ts-check

import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import Subreddit from '../components/Subreddit';
import reduce from '../store/reduce';
import { deepGetUi } from '../utility';
import ApiRequests from '../middleware/ApiRequests';

const mapStateToProps = ({ui, data}) => {
	const { showingSearchResults = true } = ui.SearchSubreddit || {};
	const sortBy = data.subreddit.sortBy;
	const actuallyShowingSearchResults = ui?.SearchSubreddit?.showingSearchResults && (ui?.SearchSubreddit?.searchResults?.length > 0)
	return {
		threads: actuallyShowingSearchResults ? ui.SearchSubreddit.searchResults : data.subreddit.threads,
		subreddit: data.subreddit.subreddit || 'popular',
		actuallyShowingSearchResults,
		sortBy,
		linkId: data.comments.linkData?.id,

	}
}

const mapDispatchToProps = dispatch => {
	return {
		changeLink: ( permalink, subreddit, linkId, itemSubreddit, moreLink ) => {
			dispatch ( push ( "/r/" + subreddit + ( linkId ? "/" + linkId : '' )));
			dispatch(reduce.menu.addRecentMenuItem(itemSubreddit));
			dispatch(reduce.more.saveMore({ permalink, moreType: "url", more: moreLink }));
		},
		changeSortBy: newSortBy => {
			dispatch(reduce.subreddit.changeSortBy(newSortBy));
			dispatch(ApiRequests.getSubreddit(undefined, newSortBy, true, undefined));
		}
	}
}

export default connect ( mapStateToProps, mapDispatchToProps )( Subreddit );