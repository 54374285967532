import More from '../components/More';
import { connect } from 'react-redux';

const mapStateToProps = state => {
	return {
		moreType: state.data.more.moreType,
		more: state.data.more.more,
		permalink: state.data.more.permalink,
	}
}

export default connect( mapStateToProps )( More );