import Api from './Api'; 

import reduce from '../store/reduce';

const NAMESPACE = "HTML_UI";
const NAMESPACE_PAT = new RegExp ( `^${ NAMESPACE }/(.*?)$` );
const AUTH_PATH_TOKEN = 'user.uid';

class htmlReduce {
	constructor () {
		this.store = null;
	}
	
	middleware = store => {
		this.store = store;

		return next => action => {
			next ( action );

			let namespaceAction = NAMESPACE_PAT.test ( action.type ) && NAMESPACE_PAT.exec ( action.type )[1];
			if ( !namespaceAction ) return;
		
			let payload = action.payload;
		
			switch ( namespaceAction ) {
			case "removeMenu":
				this.removeMenu ( payload );
				break;
			case "addMenu":
				this.addMenu ( payload );
				break;
			case "fetchMore":
				this.fetchMore ( payload );
				break;
			default:
				//console.log ( `${NAMESPACE} action ${ namespaceAction } was not used` );
			}
		}
	}

	fetchMore = ({ permalink = '', linkId, more }) => {
		const { dispatch } = this.store;
		const { router: { location: { pathname }}} = this.store.getState();
		dispatch(reduce.loading.otherLoading(true));
		Api.fetchMoreComments( linkId, more)
			.then ((more) => {
				const { router: { location: { pathname: afterFetchPathname }}} = this.store.getState();
				if(pathname !== afterFetchPathname){
					throw new Error('url changed before more load finish')
				}
				dispatch(reduce.more.saveMore({ permalink, moreType: "more", more }));
				dispatch(reduce.loading.otherLoading(false));
			})
			.catch ( err => {
				//console.log ( err );
				dispatch(reduce.loading.otherLoading(false));
			})
	}
}

const htmlActions = {
	fetchMore: (linkId, more ) => {
		return ({
			type: nameType( "fetchMore" ),
			payload: {
				permalink:"",
				linkId,
				more
			}
		})
	},
	addMenu: menu => {
		return ({
			type: nameType ( "addMenu" ),
			payload: {
				menu: menu
			}
		})
	},
	removeMenu: menu => {
		return ({
			type: nameType ( "removeMenu" ),
			payload: {
				menu: menu
			}
		})
	}
}

function nameType( subAction ){
	return NAMESPACE + "/" + subAction;
}

export { htmlReduce, htmlActions }