import React, { Component } from 'react';
import Split from 'react-split';
import { Subject } from 'rxjs'
import { sampleTime } from 'rxjs/operators';

import Api from '../controllers/Api';

import Subreddit from '../containers/Subreddit';
import Comments from '../containers/Comments';
import More from '../containers/More';
import reduce from '../store/reduce';

class Reddit extends Component{
	constructor(props){
		super(props);

		this.state = { 
			listScrollPercent: props.listScrollPercent,
			commentsScrollPercent: props.commentsScrollPercent,
			scrollLoadingSubreddit: false,
			scrollLoadingComments: false,
		}
		
		this.list = React.createRef();
		this.comments = React.createRef();
		this.more = React.createRef();

		this.commentsSubscription = React.createRef();
		this.ListSubscription = React.createRef()

		this.setShowColumn = props.setShowColumn;
		this.onDragEnd = props.onDragEnd;

		this.throttledCommentsPercent = new Subject();
		this.throttledListPercent = new Subject();
	}

	scrollLoadSubreddit = () => {
		this.setState({ scrollLoadingSubreddit: true })

		const subreddit = this.props.subreddit;
		const after = this.props.after;
		const subredditSortBy = this.props.subredditSortBy

		Api.fetchSubreddit(subreddit, after, subredditSortBy)
			.then(({ threads, subreddit }) => {
				this.props.dispatch(reduce.subreddit.appendSubreddit({ threads, subreddit }))
				this.setState({ scrollLoadingSubreddit: false })
			})
			.catch( () => this.setState({ scrollLoadingSubreddit: false }))
	}

	scrollLoadComments = () => {
		this.setState({ scrollLoadingComments: true })

		const commentId = this.props.commentId;
		const moreComments = this.props.moreComments;

		if(moreComments.length === 0) return;

		//hardcoded to 50;
		const moreChunk = moreComments.slice(0, 50);

		Api.fetchMoreComments(commentId, moreChunk)
			.then(results => {
				this.props.dispatch(reduce.comments.appendComments({ moreComments: results, commentId }))
				setTimeout(() => this.setState({ scrollLoadingComments: false }), 100)
			})
			.catch( () => this.setState({ scrollLoadingComments: false }))
	}

	componentDidMount = () => {
        this.addScrollListeners();
		this.scrollToCommentsPercent(this.state.commentsScrollPercent);
		this.scrollToListPercent(this.state.listScrollPercent);
    }

    componentWillUnmount = () => {
        this.removeScrollListeners();
    }

	addScrollListeners = () => {
		this.commentsSubscription.current = this.throttledCommentsPercent.pipe(sampleTime(250)).subscribe(percent => {
			if(percent === 0) return;
            this.props.setCommentsScrollPercent(percent)
			//console.log("comments:", this.props.commentsScrollPercent)
        })
		this.ListSubscription.current = this.throttledListPercent.pipe(sampleTime(500)).subscribe(percent => {
			if(percent === 0) return;
            this.props.setListScrollPercent(percent)
			//console.log("list:", this.props.listScrollPercent)
        })

		//if(this.showColumn !== "comments"){
			this.comments.current?.addEventListener("scroll", this.updateCommentsPercent, true);
		//}
		this.list.current.addEventListener("scroll", this.updateListPercent, true);
	}

	removeScrollListeners = () => {
		this.commentsSubscription.current.unsubscribe();
		this.ListSubscription.current.unsubscribe();

		if (this.comments && this.comments.current) {
			this.comments.current.removeEventListener("scroll", this.updateCommentsPercent, true);
		}
		if (this.list && this.list.current) {
			this.list.current.removeEventListener("scroll", this.updateListPercent, true);
		}
	}

	componentDidUpdate(prevProps) {
		//console.log("comoneent did update", prevProps, this.props);
		if ((prevProps.showColumn !== this.props.showColumn)) {
		//console.log("comoneent did update and reset scroll perecents");
			this.scrollToListPercent(this.props.listScrollPercent);
			if(this.props.showColumn !== 'details'){
				this.scrollToCommentsPercent(this.props.commentsScrollPercent);
			}
		 //readd since they seem to lose 
		  	this.removeScrollListeners();
		  	this.addScrollListeners();
		}

		if(prevProps.hideThumbnails !== this.props.hideThumbnails){
			this.scrollToListPercent(this.props.listScrollPercent);

			//readd since they seem to lose 
			this.removeScrollListeners();
			this.addScrollListeners();
		}
	  }

	setListScrollPercent = listScrollPercent => this.setState({ listScrollPercent })

	setCommentsScrollPercent = commentsScrollPercent => this.setState({ commentsScrollPercent })

	updateCommentsPercent = () => {
		//console.log("logging:", this.comments);
		if (this.comments && this.comments.current) {
			//console.log(this.comments.current.scrollTop / this.comments.current.scrollHeight);
			this.throttledCommentsPercent.next(this.comments.current.scrollTop / this.comments.current.scrollHeight);
		}
	}

	updateListPercent = () => {
		if (this.list && this.list.current) {
			//console.log(this.list.current.scrollTop / this.list.current.scrollHeight);
			this.throttledListPercent.next(this.list.current.scrollTop / this.list.current.scrollHeight)
		}
	}

	scrollToCommentsPercent = percent => {
		if(percent !== 0){
			//console.log("comment percent: ", percent);
			this.comments.current.scrollTop = (this.comments.current.scrollHeight * percent)

		}
	}

	scrollToListPercent = percent => {
		if(percent !== 0){

		//console.log("list percent:", percent);
		this.list.current.scrollTop = (this.list.current.scrollHeight * percent)
		}
	}

	resetListScroll = () => {
		this.props.setListScrollPercent(0);
		this.list.current.scrollTop = 0;
	}
	
	resetCommentsScroll = () => {
		this.props.setCommentsScrollPercent(0);
		this.comments.current.scrollTop = 0;
	}
	
	resetMoreScroll = () => {
		this.more.current.scrollTop = 0;
	}

	setColSizes = sizes => {
		this.props.setColSizes(sizes)
	}

	dragEnd = (newSizes) => {
		this.props.setColSizes(newSizes);
	}

	handleThreadScroll = (e) => {
		const threadScrollLeft = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight);
		//console.log(scrollLeft);
		if (threadScrollLeft < 500 && this.state.scrollLoadingSubreddit === false) {
			this.scrollLoadSubreddit();
		}
	}

	handleCommentsScroll = (e) => {
		const commentsScrollLeft = e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight);
		//console.log(scrollLeft);
		if (commentsScrollLeft < 850 && this.state.scrollLoadingComments === false) {
			this.scrollLoadComments();
		}
	}

	/*if(detailsOnly){
			const total = newSizes[0] + newSizes[1];
			const more = sizes[2];
			const allSizes = [ newSizes[0]/total * (100-more), newSizes[1]/total * (100-more), more]
			if(verifySizes(allSizes)){
				this.setColSizes(allSizes)
			} else {
				throw new Error("invalid split sizes");
			}
		} else {
			if(verifySizes(newSizes)) {
				this.setColSizes(newSizes)
			} else {
				throw new Error("invalid split sizes");
			}
		}
		*/

	/* getSizeArray = () => {
		//console.log("get sizes");
		//use state so it only fires when mounted, not on updates.
		const sizes = this.props.sizes;
		if(this.props.detailsOnly){
			const total = sizes[0] + sizes[1];
			return [ sizes[0] * 100/total, sizes[1] * 100/total ]
		} else {
			return sizes
		}
	} */

	render(){
		let colSizes;
		switch(this.props.showColumn){
			case "none":
				colSizes = this.props.threeCol;
				break;
			case "comments":
				colSizes = this.props.twoColCommentsOnly;
				break;
			case "details":
				colSizes = this.props.twoColDetailsOnly;
				break;
			default:
				break;
		}
		return <div id = "content">
			<Split key={this.props.showColumn} id="subreddit" className="split"
				sizes={ colSizes }
				minSize={10}
				expandToMin={false}
				gutterSize={10}
				gutterAlign="center"
				snapOffset={10}
				dragInterval={1}
				direction="horizontal"
				cursor="col-resize"
				onDragEnd={newSizes => this.dragEnd(newSizes)}
			>
				<div onScroll={this.handleThreadScroll} id = "list" ref = { this.list } key="list">
					<Subreddit hideArrows={this.props.hideArrows} hideThumbnails={this.props.hideThumbnails} resetScroll = { this.resetListScroll } showColumn={this.props.showColumn} setShowColumn = { this.props.setShowColumn }/>
				</div>
				{
					this.props.showColumn !== 'details' ? <div onScroll={this.handleCommentsScroll} id = "comments" ref = { this.comments } key="comments">
							<Comments resetScroll = { this.resetCommentsScroll } setShowColumn={this.props.setShowColumn}/>
						</div>
						: null
				}
				{
					this.props.showColumn !== 'comments' ? <div id = "more" ref={ this.more }>
							<More resetScroll = { this.resetMoreScroll }/>
						</div>
						: null
				}
			</Split>
		</div>
	}
}

export default Reddit;