export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

export const deepSetUi = (state) => {
	const {ui = {}} = state; 
    
	return slice => 
        newObj => ({ ...ui, [slice]: { ...ui[slice], ...newObj }})
}

export const deepGetUi = (state) => {
	const {ui = {}} = state;
	
	return slice => ui[slice] || {}
}

export const deepSetData = ({data}) =>
	slice =>
		newObj => ({...data, [slice]: {...data[slice], ...newObj}})

export const deepGetData = ({data}) => slice => 
	data[slice] || {}


export function roundNumber(number){
    if( number > 999 ) {
        return (number/1000).toFixed(1) + "k"
    } else {
        return number;
    }
}

/**
 * 
 * @param {Integer} length 
 * @param {Integer} sizes 
 * @returns 
 */
export function verifySizes(length, sizes){
	if(sizes.length === length && length === 2){
		return(99 < sizes[0] + sizes[1] < 101)
	} else if (sizes.length === length && length ===3){
		return (99 < sizes[0] + sizes[1] + sizes[2] < 100)
	} else {
		return false;
	}
}

export function localStorageSet(name, value){
	localStorage.setItem(name, JSON.stringify(value))
}

export function localStorageGet(name){
	return JSON.parse(localStorage.getItem(name));
}

export function extractYoutubeId(url){
	var regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
	var match = url.match(regExp);
	if (match && match[3]?.length > 0 && match[6]?.length > 0) {
	  return match[6];
	} else {
	  return false;
	}
}