import React from "react";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive" style={{ overflow: "hidden", position: "relative", height: "100%"}}>
    <iframe style={{left: 0, top: 0, height: "100%", width: "100%", position: "absolute"}}
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}?autoplay=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default React.memo(YoutubeEmbed);