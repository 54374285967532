import React, { useEffect, useRef, useState } from 'react';
import { Input, Container, Row, Col } from 'reactstrap';
import { Subject, timer, from, NEVER  } from 'rxjs'
import { switchMap, debounce, takeUntil } from 'rxjs/operators'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BsEyeSlash, BsEye, BsFillEyeFill } from 'react-icons/bs'
import reduce from '../store/reduce';

import { formatSubreddit } from '../controllers/Api'
import { deepSetUi } from '../utility';
import { connect } from 'react-redux';

const sortBy = ["relevance", "hot", "top", "new", "comments"]
const timeRange = ["hour", "day", "week", "month", "year", "all"]

const saveNewResults = newResults => (dispatch, getState) => {
    const nextState = deepSetUi(getState())('SearchSubreddit')({
        searchResults: newResults
    })

    dispatch ({
        type: "UI/SEARCH_REDDIT/saveNewResults",
        nextState,
        action: newResults
    })
}

const SearchSubreddit = props => {
    const [searchText, setSearchText] = useState('');
    const searchResultsColumn = useRef();
    const searchSubject$ = useRef(
        new Subject()
    );

    //initial load data
    useEffect(() => {
        props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SearchSubreddit')({
                showingSearchResults: true,
                searchResults: [],
                sortBy: 'relevance',
                timeRange: 'all'
            })
            
            return dispatch({
                type: 'UI/SEARCH_REDDIT/initializeSearchResults',
                nextState,
            })
        })
	}, [])

    //add subject listener
    useEffect(() => {
        searchSubject$.current.pipe(
            debounce(() => timer(500)),
            switchMap(({subreddit, term, sortBy, timeRange}) => {
                if(term === '' ) {
                    resetSearchResults();
                    return [];
                }
                //dont start load before checking search result
                props.dispatch(reduce.loading.routeLoading(true));
                return from(
                    fetch(`https://www.reddit.com/r/${subreddit}/search.json?q=${term}&restrict_sr=1&limit=100&sort=${sortBy}&t=${timeRange}`)
                        .then(results => results.json())
                        .catch(e => {
                            console.log(e);
                            return [];
                        })
                ).pipe(
                    takeUntil(searchSubject$.current)
                )
            })
        ).subscribe({
            next: results => {
                let newResults;
                if (results.length === 0) {
                    newResults = [];
                } else {
                    newResults = formatSubreddit(results);
                }
                props.dispatch(saveNewResults(newResults));
                if(searchResultsColumn?.current?.scrollTop)
                    searchResultsColumn.current.scrollTop = 0;
                props.dispatch(reduce.loading.routeLoading(false));
            },
            error: err => {
                console.log(err);
                props.dispatch(reduce.loading.routeLoading(false));
                return NEVER;
            }
        })
    }, [])

    useEffect(() => {
        searchSubject$.current.next({subreddit: props.subreddit, term: searchText, sortBy: props.sortBy, timeRange: props.timeRange})
    },[searchText, props.subreddit, props.sortBy, props.timeRange])

    const changeSearchTerm = searchText => {
        setSearchText(searchText);
    }

    const changeShowingSearchResults = status => {
        props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SearchSubreddit')({
                showingSearchResults: status,
            })
            
            return dispatch({
                type: 'UI/SEARCH_REDDIT/changeShowingSearchResults',
                nextState,
            })
        })
    }

    const resetSearchResults = () => {
        props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SearchSubreddit')({
                searchResults: [],
            })
            
            return dispatch({
                type: 'UI/SEARCH_REDDIT/resetSearchResults',
                nextState,
            })
        })
    }

    const capitalizedSubreddit = (str) => str.length > 0 ? str.charAt(0).toUpperCase() + str.slice(1) : "Popular";

    return <Container style={{backgroundColor: "white", border: "1px dashed lightgrey"}}>
        { searchText.length > 0 && props.showingSearchResults ? 
            <Row style={{fontSize: 12, marginBottom: 8, color: "grey"}}>
                <Col>
                    {sortBy.map((item, index) => <span 
                        key={item}
                        style={{padding: "0px 3px 3px", cursor: "pointer", fontWeight: props.sortBy === item ? "800" : "normal"}}
                        onClick={()=>{
                            props.dispatch((dispatch, getState) => {
                                const nextState = deepSetUi(getState())('SearchSubreddit')({
                                    sortBy: item
                                });
                                dispatch({
                                    nextState,
                                    type: "UI/SearchSubreddit/changeSortBy",
                                    action: item,
                                })
                        })
                    }}>
                        {item}
                    </span>)}
                </Col>
                <Col>
                    {timeRange.map((item, index) => <span 
                        key={item}
                        style={{padding: "0px 3px 3px", cursor: "pointer", fontWeight: props.timeRange === item ? "800" : "normal"}}
                        onClick={()=>{
                            props.dispatch((dispatch, getState) => {
                                const nextState = deepSetUi(getState())('SearchSubreddit')({
                                    timeRange: item
                                });
                                dispatch({
                                    nextState,
                                    type: "UI/SearchSubreddit/changeTimeRange",
                                    action: item,
                                })
                        })
                    }}>
                        {item}
                    </span>)}
                </Col>
            </Row>
            : null
        }
        <Row className="g-0">
            <Col style={{ flex: "0 0", marginRight: 4 }}>
                {
                    props.showingSearchResults ?
                        props.searchResults.length > 0 ? <BsFillEyeFill style={{ fontSize: 20, color: "grey"}} onClick={() => changeShowingSearchResults(false)} /> : <BsEye style={{ fontSize: 20, color: "grey"}} onClick={() => changeShowingSearchResults(false)} />
                        : <BsEyeSlash style={{ fontSize: 20, color: "grey"}} onClick={() => changeShowingSearchResults(true)} />
                }
            </Col>
            <Col style={{flex: "0 0", marginRight: 4}}>
                <AiOutlineCloseCircle style={{ fontSize: 20, color: "grey"}} onClick={() => {
                    changeSearchTerm('');
                    resetSearchResults();
                }}/>
            </Col>
            <Col style={{"alignItems": "center", display: "flex"}}>
                <Input
                    style={{lineHeight: "14px", padding: 5, fontSize: 14}}
                    value = {searchText}
                    onChange = {e => changeSearchTerm(e.target.value)}
                    placeholder = {`Search ${capitalizedSubreddit(props.subreddit)} ...`}
                />
            </Col>
               
        </Row>
    </Container>
}

export default connect(({ui, data}) => ({
    searchResults: ui?.SearchSubreddit?.searchResults,
    sortBy: ui?.SearchSubreddit?.sortBy || "relevance",
    timeRange: ui?.SearchSubreddit?.timeRange,
    subreddit: data.subreddit.subreddit,
    showingSearchResults: ui?.SearchSubreddit?.showingSearchResults,
}))(SearchSubreddit)