const routeLoading = {
	initial: {
		otherLoading: false,
		routeLoading: false
	},
	routeLoading: loadingStatus => (dispatch, getState) => {
		const state = getState().data;
		const { loading } = state;

		const nextState = { ...state, loading: {
			...loading, route: loadingStatus
		}}

		dispatch({
			type: "DATA/LOADING/routeLoading",
			nextState,
			action: {
				loading: loadingStatus
			}
		})
	},
	otherLoading: loadingStatus => (dispatch, getState) => {
		const state = getState().data;
		const { loading } = state;

		const nextState = { ...state, loading: {
			...loading, other: loadingStatus
		}}

		dispatch({
			type: "DATA/LOADING/otherLoading",
			nextState,
			action: {
				other: loadingStatus
			}
		})
	}
}

export default routeLoading;