import React from 'react';

const Page = (Content) => () => {
    return (
        <div className = "page" >
            <Content />
        </div>
    )
}

export default Page;