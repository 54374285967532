import { combineReducers } from 'redux';
import subreddit from './subreddit';
import comments from './comments';
import loading from './loading';
import menu from './menu';
import more from './more';
import { connectRouter } from 'connected-react-router';

const initialState = {
	subreddit: subreddit.initial, 
	comments: comments.initial,
	loading: loading.initial,
	more: more.initial,
	menu: menu.initial,
};

function rootReducer(state = initialState, action) {
	if(action.type.startsWith('DATA/')){
		return action.nextState;
	} else {
		return state;
	}
}

function uiReducer(state = {}, action) {
	if(action.type.startsWith('UI/')){
		return action.nextState;
	} else {
		return state;
	}
}

const createRootReducer = (history) => combineReducers({
	router: connectRouter(history),
	data: rootReducer,
	ui: uiReducer
});

export default createRootReducer;