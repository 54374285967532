import React from 'react';

const About = (props) => {
    return (
        <div id="about-content">
            <p>Hey I read a lot of social media, and I find that scrolling through Reddit can sometimes be really slow.  So I built this site.  Eventually I built more tools for Twitter, YouTube, and Twitch.  I've wrapped them all into MegaComments.com.  The other three you can use as a chrome extension in the <a href="https://chrome.google.com/webstore/detail/mega-comments-youtubetwit/pdbgnmokjckonepnfafnomiilfifcbhe">Extension Store</a></p>
            <p>If you have any questions or comments DM me on Twitter <a style={{textDecoration: "underline"}} href="https://twitter.com/derekggz">@DerekGGz</a></p>
        </div>
    )
}

export default About