import { deepGetData, deepSetData } from '../../utility'

const menu = {
	initial: {
		recentSubreddits: [],
		savedSubreddits: JSON.parse(localStorage.getItem('savedSubreddits')) || []
	},
	clearAllRecentMenu: () => (dispatch, getState) => {
		const nextState = deepSetData(getState())('menu')({
			recentSubreddits: []
		})

		dispatch({
			type: "DATA/MENU/clearAllRecentMenu",
			nextState,
		});
	},
	addRecentMenuItem: newRecent => (dispatch, getState) => {
		const { recentSubreddits } = deepGetData(getState())('menu');

		let nextState;
		
		if (newRecent === 'all' || recentSubreddits.indexOf(newRecent) > -1) {
			nextState = deepSetData(getState())('menu')({
			})
		} else {
			let newRecentMenus = recentSubreddits.slice(0, 20);
			newRecentMenus.unshift(newRecent);
			nextState = deepSetData(getState())('menu')({
				recentSubreddits: newRecentMenus
			})
		}

		dispatch({
			type: "DATA/MENU/addRecentMenuItem",
			nextState,
			action: {
				newRecent
			}
		});
	},
	removeMenu: index => (dispatch, getState) => {
		const { savedSubreddits } = deepGetData(getState())('menu');
		let newSaved = Array.from(savedSubreddits);
		newSaved.splice(index, 1);
		let nextState = deepSetData(getState())('menu')({
			savedSubreddits: newSaved
		})
		localStorage.setItem("savedSubreddits", JSON.stringify(newSaved));
		
		return dispatch({
			type: 'DATA/MENU/removeMenu',
			nextState,
			action: index
		})
	},
	addMenu: item => (dispatch, getState) => {
		const {savedSubreddits = []} = deepGetData(getState())('menu');
		let newSaved = Array.from(savedSubreddits);

		//check if value is in array
		if(newSaved.includes(item)) return;
		
		newSaved.push(item);
		newSaved.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
		let nextState = deepSetData(getState())('menu')({
			savedSubreddits: newSaved
		})

		localStorage.setItem("savedSubreddits", JSON.stringify(newSaved));
		
		return dispatch({
			type: 'DATA/MENU/addMenu',
			nextState,
			action: item
		})
	}
}

export default menu;