import Reddit from '../components/Reddit';
import { connect } from 'react-redux';

export default connect(({ data, ui }) => {
	//console.log("connect:", data);
	let subreddit, after;
	let comments, moreComments, commentId;
	
	if(data.subreddit.threads.length > 0){
		const threads = data.subreddit.threads;
		const threadsCount = threads.length;
		after = threads[threadsCount-1].linkId;
		subreddit = data.subreddit.subreddit;
	}

	const subredditSortBy = data.subreddit.sortBy;
	const commentsSortBy = data.comments.sortBy

	moreComments = data.comments.moreComments;
	commentId = data.comments.linkData.id;
	
	return {
		subreddit,
		after,
		comments,
		moreComments,
		commentId,
		subredditSortBy,
		commentsSortBy
	}
},  )( Reddit );