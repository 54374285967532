import React from 'react';
import Page from './Page';

const termsService =  
<div><p>
    <strong>Terms of Service:</strong>
</p>
<p>
    Please read this Agreement carefully before accessing or using our
    services. By accessing or using any part of our Services, you agree to
    become bound by the Terms of this Agreement. If you do not agree to all the
    Terms of this Agreement, then you may not access or use any of our
    services. If these Terms are considered an offer by MegaComments, acceptance is
    expressly limited to these Terms.
</p>
<p>
	MegaComments operates the MegaComments.com website, which provides the SERVICE.
</p>
<p>
    Our services are not directed to children younger than 13, and access and
    use of our services is only offered to users 13 years of age or older. If
    you are under 13 years old, please do not register to use our services. Any
    person who registers as a user or provides their personal information to
    our Services represents that they are 13 years of age or older.
</p>
<p>
    You will be solely responsible and liable for any activity that occurs
    under your username. You are responsible for keeping your password secure.
</p>
<p>
    <strong>1. Responsibility of Visitors.</strong>
</p>
<p>
    MegaComments has not reviewed, and cannot review, all of the material, including
    computer software, listed on our website, and cannot therefore be
    responsible for that material’s content, use or effects. By operating our
    services, MegaComments does not represent or imply that it endorses the material
    there posted, or that it believes such material to be accurate, useful, or
    non-harmful. You are responsible for taking precautions as necessary to
    protect yourself and your computer systems from viruses, worms, Trojan
    horses, and other harmful or destructive content. Our services may contain
    content that is offensive, indecent, or otherwise objectionable, as well as
    content containing technical inaccuracies, typographical mistakes, and
    other errors. Our services may also contain material that violates the
    privacy or publicity rights, or infringes the intellectual property and
    other proprietary rights, of third parties, or the downloading, copying or
    use of which is subject to additional terms and conditions, stated or
    unstated. MegaComments disclaims any responsibility for any harm resulting from
    the use by visitors of our services, or from any downloading by those
    visitors of content there posted.
</p>
<p>
    <strong>2. Content Posted on Other Websites.</strong>
</p>
<p>
    We have not reviewed, and cannot review, all of the material, including
    computer software, made available through the websites and webpages to
    which MegaComments.com links, and that link to MegaComments.com. MegaComments does not
    have any control over those non-MegaComments.com websites, and is not
    responsible for their contents or their use. By linking to a
    non-MegaComments.com website, MegaComments does not represent or imply that it
    endorses such website. You are responsible for taking precautions as
    necessary to protect yourself and your computer systems from viruses,
    worms, Trojan horses, and other harmful or destructive content. MegaComments
    disclaims any responsibility for any harm resulting from your use of
    non-MegaComments.com websites and webpages.
</p>
<p>
    <strong>3. Disclaimer of Warranties.</strong>
</p>
<p>
    Our Services are provided “as is.” MegaComments and its suppliers and licensors
    hereby disclaim all warranties of any kind, express or implied, including,
    without limitation, the warranties of merchantability, fitness for a
    particular purpose and non-infringement. Neither MegaComments nor its suppliers
    and licensors, makes any warranty that our services will be error free or
    that access thereto will be continuous or uninterrupted. You understand
    that you download from, or otherwise obtain content or services through,
    our services at your own discretion and risk.
</p>
<p>
    <strong>4. Jurisdiction and Applicable Law.</strong>
</p>
<p>
    Except to the extent applicable law, if any, provides otherwise, this
    Agreement, any access to or use of our services will be governed by the
    laws of the state of California, U.S.A., excluding its conflict of law
    provisions, and the proper venue for any disputes arising out of or
    relating to any of the same will be the state and federal courts located in
    San Francisco County, California.
</p>
<p>
    <strong>5. Arbitration Agreement.</strong>
</p>
<p>
    Except for claims for injunctive or equitable relief or claims regarding
    intellectual property rights (which may be brought in any competent court
    without the posting of a bond), any dispute arising under this Agreement
    shall be finally settled in accordance with the Comprehensive Arbitration
    Rules of the Judicial Arbitration and Mediation Service, Inc. (“JAMS”) by
    three arbitrators appointed in accordance with such Rules. The arbitration
    shall take place in San Francisco, California, in the English language and
    the arbitral decision may be enforced in any court. The prevailing party in
    any action or proceeding to enforce this Agreement shall be entitled to
    costs and attorneys’ fees.
</p>
<p>
    <strong>6. Limitation of Liability.</strong>
</p>
<p>
    In no event will MegaComments, or its suppliers or licensors, be liable with
    respect to any subject matter of this Agreement under any contract,
    negligence, strict liability or other legal or equitable theory for: (i)
    any special, incidental or consequential damages; (ii) the cost of
    procurement for substitute products or services; (iii) for interruption of
    use or loss or corruption of data. MegaComments shall have no liability for any
    failure or delay due to matters beyond their reasonable control. The
    foregoing shall not apply to the extent prohibited by applicable law.
</p>
<p>
    <strong>7. General Representation and Warranty.</strong>
</p>
<p>
    You represent and warrant that (i) your use of our Services will be in
    strict accordance with the MegaComments Privacy Policy, with this Agreement, and
    with all applicable laws and regulations (including without limitation any
    local laws or regulations in your country, state, city, or other
    governmental area, regarding online conduct and acceptable content, and
    including all applicable laws regarding the transmission of technical data
    exported from the United States or the country in which you reside) and
    (ii) your use of our Services will not infringe or misappropriate the
    intellectual property rights of any third party.
</p>
<p>
    <strong>8. Indemnification.</strong>
</p>
<p>
    You agree to indemnify and hold harmless MegaComments, its contractors, and its
    licensors, and their respective directors, officers, employees, and agents
    from and against any and all claims and expenses, including attorneys’
    fees, arising out of your use of our Services, including but not limited to
    your violation of this Agreement.
</p>
<p>
    <strong>9. Miscellaneous.</strong>
</p>
<p>
    This Agreement constitutes the entire agreement between MegaComments and you
    concerning the subject matter hereof, and they may only be modified by a
    written amendment signed by an authorized executive of MegaComments, or by the
    posting by MegaComments of a revised version.
</p>
<p>
    If any part of this Agreement is held invalid or unenforceable, that part
    will be construed to reflect the parties’ original intent, and the
    remaining portions will remain in full force and effect. A waiver by either
    party of any term or condition of this Agreement or any breach thereof, in
    any one instance, will not waive such term or condition or any subsequent
    breach thereof.
</p>
<p>
    You may assign your rights under this Agreement to any party that consents
    to, and agrees to be bound by, its terms and conditions; MegaComments may assign
    its rights under this Agreement without condition. This Agreement will be
    binding upon and will inure to the benefit of the parties, their successors
    and permitted assigns.
</p>
</div>

const privacy = <div>
<p><strong>Privacy Policy:</strong></p>
<p>MegaComments operates the MegaComments.com website, which provides the SERVICE.</p>
<p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.</p>
<p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
<p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at MegaComments.com , unless otherwise defined in this Privacy Policy.</p>
<p><strong>Information Collection and Use</strong></p>
<p>For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.</p>
<p><strong>1. Log Data.</strong></p>
<p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer&rsquo;s Internet Protocol (&ldquo;IP&rdquo;) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
<p><strong>2. Cookies.</strong></p>
<p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer&rsquo;s hard drive.</p>
<p>Our website uses these &ldquo;cookies&rdquo; to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.</p>
<p><strong>3. Service Providers.</strong></p>
<p>We may employ third-party companies and individuals due to the following reasons:</p>
<ul>
<li>To facilitate our Service;</li>
<li>To provide the Service on our behalf;</li>
<li>To perform Service-related services; or</li>
<li>To assist us in analyzing how our Service is used.</li>
</ul>
<p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
<p><strong>4. Security.</strong></p>
<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
<p><strong>5. Links to Other Sites.</strong></p>
<p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
<p><strong>Children&rsquo;s Privacy</strong></p>
<p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
<p><strong>6. Changes to This Privacy Policy.</strong></p>
<p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
<p><strong>7. Contact Us.</strong></p>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
</div>

const terms = function(){
    return <div>
        <div>
            {termsService}
        </div>
        <div>
            {privacy}
        </div>
    </div>
}

const TermsPage = Page(terms);

export { TermsPage as default, termsService, privacy }