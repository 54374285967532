//@ts-check
import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { roundNumber } from '../utility';
import { BsFillPersonFill, BsFillShiftFill, BsArrowClockwise } from 'react-icons/bs';
import { BiUpvote } from 'react-icons/bi';
import { MdOutlineQuickreply } from 'react-icons/md';
import moment from 'moment';

const sortByOptions = ["confidence", "top", "new", "controversial", "old", "random", "qa"]

const Comments = (props) => {
	const prevIdRef = useRef();

	const { id } = props;

	useEffect(
		() => {
			if(prevIdRef.current !== id && prevIdRef.current !== undefined){
				props.resetScroll();
				prevIdRef.current = id
			} else if ( prevIdRef.current === undefined){
				prevIdRef.current = id
			}
		},
		[id]
	);
	
	let { linkData } = props;

	return (
		<div key = { linkData.id } className = "links">
			{
				linkData.title
					? <Helmet>
						<title>{ linkData.title } - Alternative Browser for Reddit</title>
						<meta property="description" content={ linkData.title  + " - Mega Comments for Reddit"} />
						<meta property="og:title" content={ linkData.title  + " - Mega Comments for Reddit"} />
						<meta property="og:type" content="website" />
						<meta property="og:description" content={ "Better Reddit Experience: Easier, Faster, Smarter" } />
						<meta property="og:image" content={linkData.ogImage} />
						<meta property="og:image:height" content={linkData.height} />
						<meta property="og:image:width" content={linkData.width} />

						<meta name="twitter:card" content="summary" />
						<meta name="twitter:title" content={ linkData.title + " - Mega Comments for Reddit"}/>
						<meta name="twitter:description" content={ "Better Reddit Experience: Easier, Faster, Smarter" } />
			{/*<meta name="twitter:url" content={linkData.url} />*/}
						<meta name="twitter:image" content={linkData.ogImage} />
					</Helmet>
					: null
			}
			{
				linkData.title
					? <div id="link-data">
						<a id="link-title" rel="noopener noreferrer" target="_blank" href={linkData.url}>{linkData.title}</a>
						
						<div className="meta">
							<span className="score meta-item"><BsFillShiftFill style = {{ color:"LightGrey", marginRight: "3px",  marginBottom: "3px"  }}/>{ roundNumber( linkData.score  )}</span>&nbsp;
							<span className="author meta-item"><BsFillPersonFill style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }}/><a href={ "https://reddit.com/user/" + linkData.author} rel="noreferrer" target="_blank" onClick={e=>e.stopPropagation()}>{ linkData.author }</a></span>
							{/*<span className="comments meta-item">{linkData.numComments}</span>*/}
							<span className="subreddit meta-item"><Link to = {"/r/" + linkData.subreddit}>/{linkData.subreddit}</Link></span>
							<span className="domain meta-item"><a rel="noreferrer" href={linkData.url} target="_blank" onClick={e=>e.stopPropagation()}>{linkData.domain}</a></span>
						</div>
						<div style={{textAlign: "right", marginRight: 7, marginTop: 1, marginBottom: 1, fontSize: 12, color: 'grey'}}>
							{ sortByOptions.map((item, index) => <span 
								key={item}
								style={{cursor: "pointer", padding: "0px 3px 3px", fontWeight: props.sortBy === item ? 800 : "normal"}}
								onClick={()=>props.changeSortBy(item)}>
									{item}
								</span>
							)}
						</div>
					</div>
					: null
			}
			{ 
				props.comments 
					? <div className="content">
						{displayThread(props.comments, 0, linkData.id, linkData.id)}
					</div>
					: null
			}
		</div>
	);



	function changeVisibleExpand (event) {
		event.stopPropagation();
		event.currentTarget.parentNode.classList.toggle("hidden");
	}

	function changeVisibleDiv ( event ) {
		event.stopPropagation();
		event.currentTarget.classList.toggle("hidden");
	}

	/*function rollUp ( event, commentId ) {
		event.stopPropagation();

		let parentNode = event.currentTarget.parentNode.parentNode;
		let childNodes = parentNode.childNodes;

		for ( let i = 0; i < childNodes.length; i++) {
			if ( childNodes[i].classList.contains ( "thread" )){
				if ( childNodes[i].id === commentId ){
					break;
				}
				childNodes[i].classList.add( 'hidden' );
			}
		}

		scrollToId( parentNode.id );
	}*/

	function fetchMoreButton ( event, items, linkId ) {
		event.stopPropagation();
		//console.log("FETCH MORE BUTTONid: ", event, items, id, detailsOnly);
		props.fetchMore( linkId, items ).then(() => {
			/* if(detailsOnly){
				//console.log("scroll to id:", id);
				//scrollToId(id)
			} */
		})
			//.then(() => {
			//})
	}

	function displayThread ( thread, depth, id = '', linkId ) {
		let nextDepth = depth + 1;

		return thread.map (( item ) => {
			if ( item.body ) {
				//console.log(item);
				//let quote = `${item.body} - ${item.author}`;
				//let href = `https://OtherIt.com/r/${props.linkData.subreddit}/${props.linkData.id}#${item.id}`;
				return <div onClick = { event => changeVisibleDiv ( event )} key = { item.id } id = { item.id } className = { depth%2 === 0 ? "even thread" : "odd thread" }>
					<div className="roll-up" onClick={event => changeVisibleExpand(event)}/>
					<div className="thread-text" onClick={event => changeVisibleExpand(event)}>
						<div>
							{ item.body }
						</div>
						<span className="comment-meta">
							{ roundNumber( item.score )}&nbsp;&nbsp;
							<a rel="noreferrer" href={"https://www.reddit.com/user/" + item.author} target="_blank" onClick={e=>e.stopPropagation()}>{item.author}</a>
							<a rel="noreferrer" target="_blank" href={"https://reddit.com" + linkData.permalink + item.id} onClick={e=>e.stopPropagation()}><MdOutlineQuickreply style={{fontSize:"15px", marginLeft: "7px", paddingBottom:"1px", color: "lightgrey"}}/></a>
							<span style={{marginLeft: 7}}>{moment(item.created*1000).fromNow(true)}</span>
						</span>
						<span className="comment-meta">
							
							{item.threadCommentCount > 1 ? " (" + item.threadCommentCount + ")" : null}
							{item.authorData ?
									<>
										<br></br>
										<a rel="noreferrer" target="_blank" href={"https://reddit.com" + linkData.permalink + item.id} onClick={e=>e.stopPropagation()}>
											<span >{item.authorData.age}yr </span>
											<span ><BiUpvote style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }} />{item.authorData.link_karma}k-</span>
											<span >{item.authorData.comment_karma}k </span>
											<span ><BsArrowClockwise style = {{ color:"LightGrey", marginRight: "3px", marginBottom: "3px" }} />{item.authorData.comments_per_month}/month </span>
										</a>
									</>
									: null
							}		
							
						</span>
						{/*<span className="twitter">
							<a className="twitter-link" target="_blank" onClick={event => event.stopPropagation()} href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(`${item.body} - ${item.author}`) + "&url=" + encodeURIComponent(`https://OtherIt.com/r/${props.linkData.subreddit}/${props.linkData.id}#${item.id}`)}>
								<img src="/img/twitter.png" alt="twitter" />
							</a>
						</span>
						<span className="facebook">
							<img src="/img/facebook.png" alt="facebook" onClick={
								(event)=> {
									event.stopPropagation();
									facebookShare(event, quote, href)
								}
							} />
						</span>*/}
					</div> 
					{item.thread && displayThread(item.thread, nextDepth, item.id, linkId )}
				</div>;
			} else if ( item.more ) {
				let more = [];
				let count = item.more.length;
				
				for ( let i = 0; i*100 < count; i++ ){
					let ids = item.more.slice( i*100, (i+1)*100 );
					more.push(<div id={id} key = { id + "more" + i } className={"more-button"} onClick={ event => fetchMoreButton( event, ids, linkId )}>More {((count-(i+1)*100) > 0 ? 100 : count % 100)}</div>);
				}
				
				return <div key={id + "more"} className={depth%2 === 0 ? "even more" : "odd more"} onClick={(event) => event.stopPropagation()}>
					<div className="more-expand"/>
					{ more }
					<div className="spacer" />
				</div>;
			} else {
				return null;
			}
		});
	}
};

export default Comments;