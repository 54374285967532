import React, { useEffect } from 'react';
import { MdOutlineQuickreply } from 'react-icons/md';
import { roundNumber } from '../utility';
import YoutubeEmbed from './YouTube';
import { extractYoutubeId } from '../utility';
import moment from 'moment';

const More = React.memo(props => {
	const { more } = props;
	useEffect(()=>{
		props.resetScroll();
	}, [more]);

	let moreType = props.moreType;

	if(moreType === "image") {
		return <div className="image-more" key={props.more}>
			<img alt="thumbnail" style={{maxWidth: "100%"}} alt-text="image preview" src={props.more} />
			{/* <div style={{textAlign: "right", marginRight: "10px", marginTop: "10px"}}>
				<a href = {props.redditLink} target="_blank" rel="noopener noreferrer">
					<span style = {{ color: "#949494"}}>
						Thread
					</span>
				</a>
			</div> */}
		</div>
	} else if (moreType === "reddit-video") {
		const mediaPermalink = props.permalink.replace("reddit", "www.redditmedia")
		return <div className="reddit-video-more" key={props.more}>
			<iframe title="reddit-video-more" id="reddit-embed" allowFullScreen={true} src={`${mediaPermalink}?ref_source=embed&amp;ref=share&amp;embed=true`} sandbox="allow-scripts allow-same-origin allow-popups" style={{height: "90vh", width:"100%"}}/>
			 {/*<div style={{textAlign: "right", marginRight: "10px", marginTop: "10px"}}>
			<iframe id="reddit-embed" src="https://www.redditmedia.com/r/undefined/comments/rb910p?ref_source=embed&amp;ref=share&amp;embed=true" sandbox="allow-scripts allow-same-origin allow-popups" style={{height: "90vh", width:"100%"}} scrolling="no"></iframe>
				<a href = {props.redditLink} target="_blank" rel="noopener noreferrer">
					<span style = {{ color: "#949494"}}>
						Thread
					</span>
				</a>
			</div> */}
		</div>
	} else if(moreType === 'reddit-embed' || moreType === "reddit-gallery"){
		const mediaPermalink = props.permalink.replace("reddit", "www.redditmedia")
		return (<div key={props.more}>
			<iframe title="reddit-embed" id="reddit-embed" allowfullscreen="true" src={`${mediaPermalink}?ref_source=embed&amp;ref=share&amp;embed=true`} sandbox="allow-scripts allow-same-origin allow-popups" style={{height: "90vh", width:"100%"}}/>
			</div>)
	} else if(moreType === 'gfycat'){
		const mediaUrl = props.more.replace('https://gfycat.com', 'https://gfycat.com/ifr')
		//return <iframe src={mediaUrl} frameborder='0' scrolling='no' allowfullscreen width='640' height='456'></iframe>
		return <div style={{position:'relative', paddingBottom:'calc(152.61% + 44px)'}}><iframe src={mediaUrl} style={{ frameborder:'0', scrolling:'no', width:'100%', height:'100%', maxHeight: "90vh", position:"absolute", top:0, left:0}} allowfullscreen></iframe></div>
	} else if(moreType === "url" && extractYoutubeId(props.more)) {
		return (<div key={props.more} style={{width: "100%", height: "100%"}}>
				<YoutubeEmbed embedId={extractYoutubeId(props.more)}/> 
			</div>
			)
	} else if(moreType === "url" && props.more ) {
		return (<div key={props.more}>
			<a href={props.more} className="embedly-card"></a>
			{/* <div style={{textAlign: "right", marginRight: "10px", marginTop: "10px"}}>
				<a href = {props.redditLink} target="_blank" rel="noopener noreferrer">
					<span style = {{ color: "#949494"}}>
						Thread
					</span>
				</a>
			</div> */}
			{
			//<a id="embedly"  data-card-controls="0" data-card-type="article-full" href={props.more} className="embedly-card"></a>
			}
		</div>);
	} else {
		return props.more
			? <div className="content">
				{displayThread(props.more, 0)}
			</div>
			: null;
	}
})

function scrollToId(id) {
	var elem = document.getElementById(id); //chat container's ID where your messages stack up.
	if (elem) {
		elem.scrollIntoView();
	}
}

function changeVisibleExpand(event) {
	event.stopPropagation();
	event.currentTarget.parentNode.classList.toggle("hidden");
}

function changeVisibleDiv(event) {
	event.stopPropagation();
	event.currentTarget.classList.toggle("hidden");
}

function rollUp(event, commentId){
	event.stopPropagation();

	let parentNode = event.currentTarget.parentNode.parentNode;
	let childNodes = parentNode.childNodes;

	for(let i = 0; i < childNodes.length; i++) {
		if(childNodes[i].classList.contains("thread")){
			if(childNodes[i].id === commentId){
				break;
			}
			childNodes[i].classList.add('hidden');
		}
	}

	scrollToId(parentNode.id);
}

function displayThread(thread, depth) {
	//console.log("more thread, ", thread);
	let nextDepth = depth + 1;

	return thread.map(( item ) => {
		if( item.body ) {
			return <div onClick={ event => changeVisibleDiv( event ) } key={ item.id } id={ item.id } className={ depth%2 === 0 ? "even thread" : "odd thread"}>
				<div className="roll-up" onClick={event => rollUp(event, item.id)}/>
				<div className="thread-text" onClick={event => changeVisibleExpand(event)}>
					<div>
						{item.body}
					</div>
					<span className="comment-meta">
						{ roundNumber( item.score )}&nbsp;&nbsp;
						<a rel="noreferrer" style={{color: "#949494"}} target="_blank" href = { "https://reddit.com/user/" + item.author } onClick = { e => e.stopPropagation() }>{item.author}</a>
						<a rel="noreferrer" target="_blank" href={"https://reddit.com" + item.permalink} onClick={e=>e.stopPropagation()}><MdOutlineQuickreply style={{fontSize:"15px", paddingBottom:"1px", color: "lightgrey", marginLeft: "7px"}}/></a>
						<span style={{marginLeft: 6}}>{moment(item.created*1000).fromNow(true)}</span>
					</span>
					{/*<span className="comment-meta"> [{item.author}] [{item.score}]<a className="reddit-link" target="_blank" rel="noopener noreferrer" href={"https://reddit.com" + item.permalink + item.id}> ></a></span>*/}
				</div> 
				{item.thread && displayThread(item.thread, nextDepth, item.id )}
			</div>;
		} else {
			//console.log("moreId", item);
			return <div key={item.id} id={item.id} className={depth%2 === 0 ? "even more" : "odd more"}><a onClick={e => e.stopPropagation()} target="_blank" rel="noopener noreferrer" href={"https://reddit.com" + item.permalink} className="continue-reddit">Thread Continues at Reddit</a></div>;
		}
	});
}

export default More;