//import fetch from 'isomorphic-unfetch';
//import { Subject } from 'rxjs'

import CONFIG from '../config';
//const API_DOMAIN = "http://localhost:5001/otherit-9954a/us-central1/api/api"; //CONFIG.REACT_APP_API_DOMAIN;
//const ORIGIN = "https://megacomments.com"; //CONFIG.REACT_APP_ORIGIN;
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "https://megacomments.com/api"

const NAMESPACE = "API";
const NAMESPACE_PAT = new RegExp ( `${NAMESPACE}/.?` );

//const subredditRx = new Subject();
//const commentsRx = new Subject();
//const moreRx = new Subject();

class Api {
	fetchSubreddit = ( subreddit = "popular", after, sortBy = 'hot' ) => {
		//let url = API_DOMAIN + "/subredditGet" + "?subreddit=" + subreddit;
        
		let url = `https://www.reddit.com/r/${subreddit}/${sortBy}.json?limit=25${after ? `&after=t3_${after}` : ''}`

		return fetch ( url, {
			method: "GET",
		})
			.then( results => {
				if (results.ok) return results.json();
				else throw new Error("fetch error getSubreddit");
			})
			.then(results => {
				let threads = formatSubreddit(results)
				return({ subreddit, threads})
			})
	}
	
	fetchComments = ( subreddit = 'popular', linkId, sortBy = 'confidence' ) => {
		//let url = API_DOMAIN + "/commentsGet" + "?linkId=" + linkId;
		let url = `https://www.reddit.com/r/${subreddit}/comments/${linkId}.json?limit=50&depth=3&sort=${sortBy}`
		return fetch ( url, {
			method: "GET",
		})
			.then( results => {
				if (results.ok) return results.json();
				else throw new Error("fetch error getSubreddit");
			})
			.then( response => formatComments(response))
	}

    /**
     * 
     * @param {*} linkId 
     * @param {*} more 
     * @param {*} uid 
     * @returns 
     */
	fetchMoreComments = ( linkId, more, uid = 'DO_NOT_TRACK_THIS_DEVICE' ) => {
		//let url = API_DOMAIN + "/moreGet" + "?linkId=" + linkId + "&more=" + more;
		let url = `https://www.reddit.com/api/morechildren.json?children=${more}&link_id=t3_${linkId}&depth=3&api_type=json`

		return fetch( url, {
			method: "GET",
		})
			.then(results => {
				if ( results.ok ) return results.json();
				else throw new Error ( "fetch getMore not ok" );
			})
			.then(response => formatMore(response))
	}
}

export default new Api();

function formatComments(commentsData){
    let { over_18, permalink, url, created, title, subreddit = "all", domain, id, author, score, num_comments } = commentsData[0].data.children[0].data;
            
    let ogImage, width, height;
    if ( commentsData[0].data.children[0].data.preview ) {
        ({ url: ogImage, width, height } = commentsData[0].data.children[0].data.preview.images[0].source);
    }

    if (over_18) {
        throw new Error('content over 18');
    }
    
    let comments = recursiveComments(commentsData[1].data.children || []);
    
    const data = {
        linkData:{
            permalink, 
            url,
            created, 
            title, 
            subreddit, 
            domain, 
            created, 
            id, 
            author, 
            score, 
            num_comments,
            ogImage: ogImage || '',
            width: width || '',
            height: height || ''
        },
        comments
    }

    return data;
}

function recursiveComments ( arr ) {
    let out = [];
    for ( var i = 0; i < arr.length; i++ ) {
        if ( arr[i].kind === "t1" ) {
            let comment = ({
                body: arr[i].data.body,
                score: arr[i].data.score,
                author: arr[i].data.author,
                id: arr[i].data.id,
                created: arr[i].data.created_utc
            })

            if ( arr[i].data.replies !== '' ) {
                comment.thread = recursiveComments ( arr[i].data.replies.data.children );
            }

            out.push ( comment );
        }
        else if( arr[i].kind === "more" ) {
            let comment = ({
                more: arr[i].data.children
            })
            out.push ( comment );
        }
    }
    return out;
}

export function formatSubreddit(response){
	let threads = [];
	for(let i = 0; i < response.data.children.length; i++) {
		const data = response.data.children[i].data;
		if(!data.over_18) {
			threads.push({
				domain: data.domain,
				subreddit: data.subreddit,
				linkId: data.id,
				thumbnail: data.thumbnail,
				url: data.url,
				created: data.created_utc,
				title: data.title,
				numComments: data.num_comments,
				score: data.score,
				permalink: data.permalink,
				author: data.author
			})
		}
	}
	return threads;
}

function formatMore(response) {
	let list = response.json.data.things;
    var map = {}; 
	var node;
	var roots = []; 

    let regex = /(\/r\/.*?\/comments\/.*?\/.*?\/).*/
    let permalink = regex.exec(list[0].data.permalink)[1];
    for (let i = 0; i < list.length; i += 1) {
        let data = list[i].data;
        if(!data.body) {
            //is 'more'
            list[i] = {
                id: data.id,
                permalink: permalink + data.parent_id.replace("t1_", ""),
                parentId: data.parent_id
            }
        } else {
            list[i] = {
                body: data.body,
                id: data.id,
                score: data.score,
                author: data.author,
                parentId: data.parent_id,
                permalink: data.permalink,
                created: data.created_utc
            }
        }
        map["t1_" + list[i].id] = i; // initialize the map
        list[i].thread = []; // initialize the children
    }
    
    for (let i = 0; i < list.length; i += 1) {
        node = list[i];
        if (map.hasOwnProperty(node.parentId)) {
            list[map[node.parentId]].thread.push(node);
        } else {
            roots.push(node);
        }
    }
    
    return roots;
}