const more = {
	initial: {
		more: "",
		moreType: "url",
        permalink: "",
	},

	/**
	 * 
	 * @param {Object} moreInfo 
	 * @param {string} moreInfo.more - more data
	 * @param {string} moreInfo.moreType - either 'more' or 'url'
	 * @param {string} moreInfo.permalink - reddit permalink, needed for videos
	 * 
	 */
	saveMore: ({ more, moreType, permalink }) => (dispatch, getState) => {
		const state = getState().data;

        if(moreType !== "more"){
            moreType = getType(moreType, more);
        }

		const nextState = { ...state, more: { ...state.more,
			more,
            moreType,
			permalink
		}}

		dispatch({
			type: "DATA/MORE/saveMore",
			nextState,
			action: {
				more,
                moreType,
				permalink
			}
		})
	},
}

function getType(moreType, more) {
	if(typeof(more) === "string"){
		if(more.match(/\.(jpeg|jpg|png|gif)$/) != null) return 'image';
		else if (more.match(/^https:\/\/v.redd.it/) != null) return 'reddit-video';
		else if (more.match(/^https:\/\/(www\.)?reddit.com\/gallery/) != null) return 'reddit-gallery';
		else if (more.match(/^https:\/\/(www\.)?reddit.com/) != null) return 'reddit-embed';
		else if (more.match(/^https:\/\/gfycat.com/) != null) return 'gfycat';
	}
	//return "url";
	return moreType;
}


export default more;