import React, { useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';

import { MdOutlineHideImage, MdOutlineImage } from 'react-icons/md'
import { BsFillArrowRightSquareFill, BsArrowRightShort, BsFillArrowLeftSquareFill, BsArrowLeftShort } from 'react-icons/bs'
import { CgArrowsMergeAltH } from 'react-icons/cg';

import MainMenu from '../containers/SavedMenu';
import About from './About';
import SearchReddit from '../containers/SearchReddit';
import SavedSubreddits from '../containers/SavedSubreddits';
import VisitedSubreddits from '../containers/VisitedSubreddits';

const Footer = props => {
	const [ terms, setTerms ] = useState( false );
	const [ menu, setMenu ] = useState( false );
	const [ about, setAbout ] = useState( false );
	const [ showingTab, setShowingTab ] = useState('none');

	const toggleMenu = () => {
		setMenu( !menu );
	}

	const closeMenu = () => {
		setMenu( false );
	}

	const toggleAbout = () => {
		setAbout( !about );
	}

	const toggleTerms = () => {
		setTerms( !terms );
	}

	return (
		<div id = "footer">
			<div id="button-show">
				<SearchReddit showingTab={showingTab} setShowingTab={setShowingTab}/>
				{showingTab === "saved" ? <SavedSubreddits setShowingTab={setShowingTab} /> : null}
				{showingTab === "visited" ? <VisitedSubreddits setShowingTab={setShowingTab} /> : null}
				<button className="show-thumbnails" onClick={props.hideThumbnails ? () => props.setHideThumbnails(false) : () => props.setHideThumbnails(true)}>{props.hideThumbnails ? <MdOutlineHideImage /> : <MdOutlineImage />}</button>
				<button className="show-arrow" onClick={props.showColumn === 'comments' ? () => props.setShowColumn('none') : () => props.setShowColumn('comments') }>
					{props.showColumn === 'comments' ?
							<BsFillArrowRightSquareFill style={{ color: "#808080", fontSize: "20px", paddingBottom: 2}} />
							: <BsArrowRightShort style={{ color: "#808080", fontSize: "20px", paddingBottom: 2}} />
					}
				</button>
				<button className="show-arrow" onClick={props.showColumn === 'details' ? () => props.setShowColumn('none') : () => props.setShowColumn('details')}>
					{props.showColumn === 'details' ?
							<BsFillArrowLeftSquareFill style={{ color: "#808080", fontSize: "20px", paddingBottom: 2}} />
							: <BsArrowLeftShort style={{ color: "#808080", fontSize: "20px", paddingBottom: 2}} />
					}
				</button>
				<button className="show-thumbnail-arrows" onClick={props.hideArrows ? () => props.setHideArrows(false) : () => props.setHideArrows(true)}>{props.hideArrows ? <CgArrowsMergeAltH style={{color: "#d1d1d1"}} /> : <CgArrowsMergeAltH style={{color: "#4c4c4c"}} />}</button>
				<button style={{fontWeight: showingTab === 'search' ? "bold" : "normal"}}id="menu-button" onClick={() => setShowingTab(showingTab === 'search' ? 'none' : 'search')}>
					Search
				</button>
				<button style={{fontWeight: showingTab === 'saved' ? "bold" : "normal"}}id="menu-button" onClick={() => setShowingTab(showingTab === 'saved' ? 'none' : 'saved')}>
					Saved
				</button>
				<button style={{fontWeight: showingTab === 'visited' ? "bold" : "normal"}}id="menu-button" onClick={() => setShowingTab(showingTab === 'visited' ? 'none' : 'visited')}>
					History
				</button>
				<button >
					<Link to="/">r/Popular</Link>
				</button>
				<a id="chrome-bonus" target="_blank" rel="noreferrer" href="https://chrome.google.com/webstore/detail/megacomments-youtube-twit/pdbgnmokjckonepnfafnomiilfifcbhe?hl=en">
					<button >
					Chrome Bonus
					</button>
				</a>
				<Modal id=""
					style={{content:{
						bottom:"initial"
					}}}
					onRequestClose = {toggleMenu}
					shouldCloseOnOverlayClick={true}
					isOpen={menu}
				>
					<button style = {{ width: "100%" }} onClick={() => toggleMenu()}>
						close
					</button>
					<MainMenu closeMenu = { closeMenu }/>
					<button style = {{ width: "100%" }} onClick={() => toggleMenu()}>
						close
					</button>
				</Modal>
				<button id="about-button" onClick={() => toggleAbout()}>
					About
				</button>
				<Modal
					style = {{content:{
						bottom:"initial"
					}}}
					onRequestClose = {toggleAbout}
					shouldCloseOnOverlayClick={true}
					isOpen={about}
				>
					<button style = {{ width: "100%" }} onClick={() => toggleAbout()}>
						close
					</button>
					
					<About />
				</Modal>
				<button id="terms-button" onClick={() => toggleTerms()}>
					<Link to="/tos">Terms & Privacy</Link>
				</button>
				<hr id="footer-break" style={{ flexBasis: "100%", height: 0, margin: 0, border: 0, display: 'none' }} />
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		rules: state.data.subreddit.rules,
		subreddit: state.data.subreddit.subreddit
	}
}

export default connect(mapStateToProps)(Footer);