import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { TiDeleteOutline } from 'react-icons/ti';

import reduce from '../store/reduce';

const SavedMenu = props => {
	const textInput = React.createRef();

	return (
		<Container style={{backgroundColor: "white", padding: 5, border: "1px dashed lightgrey", maxHeight: "calc(90vh - 100px)", overflow: "auto"}}>
            <Row className="g-0">
                <Col style={{display: "flex", flexDirection: "column"}}>
                    {props.savedSubreddits.map((item, index) => {
                        return <span style={{flex: "0 1 auto", width: "100%"}} key={index + item} className="menu-item">
                            <span className="menu-delete" onClick={()=>props.removeMenu(index)}><TiDeleteOutline /></span>
                            <Link style={{fontSize: 16, fontWeight: "bold"}} onClick={() => {}} className="menu-link" to={"/r/" + item}>{item}</Link>
                        </span>;
                    })}
                </Col>
            </Row>
			<Row className="g-0">
                <Col>
                    <form id = "add-menu" style={{fontSize: "14px", marginLeft: 5, marginTop: 5}}>
                        {/*removed ref here, will need to refix todo*/}
                        <input id="add-input" ref={textInput} type="text" />
                        <button onClick = { e => 
                            {
                                e.preventDefault();
                                props.addMenu(textInput.current.value);
                                textInput.current.value = ''
                            }
                        } 
                        type="submit">
                            Add to Saved
                        </button>
                    </form>			
                </Col>
            </Row>
		</Container>
	)
}

export default connect(
    ({data}) => ({
        savedSubreddits: data.menu.savedSubreddits
    }),
    (dispatch) => ({
        removeMenu: index => {
            dispatch(reduce.menu.removeMenu(index))
        },
        addMenu: item => {
            dispatch(reduce.menu.addMenu(item))
        }
    })
)(SavedMenu);