import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

//pulled from https://frontpagemetrics.com/top-sfw-subreddits, converted to column in excel, formatted in https://capitalizemytitle.com/tools/column-to-comma-separated-list/
const top100List = ["announcements","funny","AskReddit","gaming","aww","Music","pics","worldnews","science","todayilearned","movies","videos","news","Showerthoughts","Jokes","food","EarthPorn","askscience","IAmA","gifs","nottheonion","books","LifeProTips","explainlikeimfive","Art","DIY","sports","mildlyinteresting","space","gadgets","Documentaries","blog","tifu","photoshopbattles","GetMotivated","UpliftingNews","memes","listentothis","dataisbeautiful","television","history","philosophy","InternetIsBeautiful","Futurology","WritingPrompts","OldSchoolCool","nosleep","personalfinance","creepy","TwoXChromosomes","wallstreetbets","technology","wholesomememes","AdviceAnimals","interestingasfuck","Fitness","politics","WTF","oddlysatisfying","lifehacks","travel","relationship_advice","Minecraft","facepalm","NatureIsFuckingLit","Whatcouldgowrong","BlackPeopleTwitter","leagueoflegends","pcmasterrace","bestof","nextfuckinglevel","me_irl","dankmemes","Tinder","Unexpected","PS4","dadjokes","buildapc","AnimalsBeingBros","tattoos","photography","AnimalsBeingJerks","MadeMeSmile","Damnthatsinteresting","nba","trippinthroughtime","BikiniBottomTwitter","AnimalsBeingDerps","gardening","CryptoCurrency","FoodPorn","WatchPeopleDieInside","instant_regret","reactiongifs","woahdude","mildlyinfuriating","programming","Overwatch","PublicFreakout","EatCheapAndHealthy"];
const top200List = ["pokemon","Parenting","Bitcoin","PewdiepieSubmissions","ContagiousLaughter","boardgames","NintendoSwitch","malefashionadvice","pokemongo","woodworking","itookapicture","iphone","stocks","IdiotsInCars","xboxone","AmItheAsshole","Outdoors","dating_advice","Awwducational","rarepuppers","YouShouldKnow","nonononoyes","HistoryMemes","HighQualityGifs","cats","relationships","AskMen","drawing","soccer","Games","Eyebleach","cursedcomments","loseit","anime","GifRecipes","HumansBeingBros","streetwear","europe","humor","HistoryPorn","apple","gameofthrones","AskWomen","BetterEveryLoop","BeAmazed","NetflixBestOf","confession","Cooking","blackmagicfuckery","howto","entertainment","MakeupAddiction","recipes","HolUp","backpacking","slowcooking","CrappyDesign","pcgaming","socialskills","atheism","DeepIntoYouTube","keto","teenagers","MovieDetails","cars","ChildrenFallingOver","MurderedByWords","Wellthatsucks","OutOfTheLoop","scifi","biology","youtubehaiku","trashy","offmychest","learnprogramming","battlestations","KidsAreFuckingStupid","horror","DnD","frugalmalefashion","starterpacks","raspberry_pi","NoStupidQuestions","mac","rickandmorty","TrollYChromosome","HomeImprovement","WhitePeopleTwitter","hardware","therewasanattempt","Coronavirus","coolguides","ksi","Android","foodhacks","Sneakers","bodyweightfitness","camping","blursedimages","dogs"];

const Menu = props => {
	const { menu, recent, closeMenu } = props;

	const menuDisplay = menu.map((item, index) => {
		return <span key={index + item} className="menu-item">
			<Link onClick={() => closeMenu()} className="menu-link" to={"/r/" + item}>{item}</Link>
			<span className="menu-delete" onClick={()=>props.removeMenu(index)}>x</span>
		</span>;
	});

	const recentDisplay = recent.map((item, index) => {
		return <span key={index + item} className="menu-item">
			<Link onClick={() => closeMenu()} className="menu-recent" to={"/r/" + item}>{item}</Link>
			<span className="menu-add" onClick={()=>props.addMenu(item)}>+</span>
		</span>;
	});

	const top100 = top100List.map((item, index) => {
		return <span key={index+item} className="menu-item">
			<Link onClick={() => closeMenu()} className="menu-recent" to={"/r/" + item}>{item}</Link>
			<span className="menu-add" onClick={()=>props.addMenu(item)}>+</span>
		</span>
	})

	const top200 = top200List.map((item, index) => {
		return <span key={index+item} className="menu-item">
			<Link onClick={() => closeMenu()} className="menu-recent" to={"/r/" + item}>{item}</Link>
			<span className="menu-add" onClick={()=>props.addMenu(item)}>+</span>
		</span>
	})

	return <div id="menu">
		<Row id = "saved-subreddits-menu">
			<Col>
				<b>Saved Subreddits</b><br/>
					{ menuDisplay }
			</Col>
		</Row>
		<Row id = "recent-subreddits-menu">
			<Col>
				<b>Recent Subreddits</b><br/>
					{ recentDisplay }
			</Col>
		</Row>
		<Row>
			<Col id="popular-subreddits-menu">
				<b>Top 100 Subreddits</b><br/>
					{ top100 }
			</Col>
		</Row>
		<Row>
			<Col id="popular-subreddits-menu">
				<b>Top 100 - 200</b><br/>
					{ top200 }
			</Col>
		</Row>
	</div>;
};

export default Menu;