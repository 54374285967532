import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { deepSetUi, deepGetUi } from '../utility';

import Menu from '../containers/Menu';

const SavedMenu = props => {
	const textInput = React.createRef();
	
	const changeMenuShown =  menuStatus => {
		props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SavedMenu')({
                menuShown: menuStatus
            })

            return dispatch({
                type: 'UI/SAVED_MENU/changeMenuShown',
                nextState,
                action: menuStatus
            })
        })
	}

    const changeLoginShown =  menuStatus => {
		props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SavedMenu')({
                loginShown: menuStatus
            })

            return dispatch({
                type: 'UI/SAVED_MENU/changeLoginShown',
                nextState,
                action: menuStatus
            })
        })
	}

	const removeMenu = index => {
        props.dispatch((dispatch, getState) => {
            const {menu = {}} = deepGetUi(getState())('SavedMenu');
            let newMenu = Array.from(menu);
            newMenu.splice(index, 1);
            let nextState = deepSetUi(getState())('SavedMenu')({
                menu: newMenu
            })
            localStorage.setItem("savedSubreddits", JSON.stringify(newMenu));
            
            return dispatch({
                type: 'UI/SAVED_MENU/removeMenu',
                nextState,
                action: index
            })
        })
	}

    const addMenu = item => {
        props.dispatch((dispatch, getState) => {
            const {menu = {}} = deepGetUi(getState())('SavedMenu');
            let newMenu = Array.from(menu);

			//check if value is in array
			if(newMenu.includes(item)) return;
            
			newMenu.push(item);
		    newMenu.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'}))
            let nextState = deepSetUi(getState())('SavedMenu')({
                menu: newMenu
            })

            localStorage.setItem("savedSubreddits", JSON.stringify(newMenu));
            
            return dispatch({
                type: 'UI/SAVED_MENU/addMenu',
                nextState,
                action: item
            })
        })
	}

	useEffect(() => {
		let menu = JSON.parse(localStorage.getItem('savedSubreddits')) || []
        props.dispatch((dispatch, getState) => {
            let nextState = deepSetUi(getState())('SavedMenu')({
                menuShown: false,
                loginShown: false,
                menu,
            })
            
            return dispatch({
                type: 'UI/SAVED_MENU/loadMenu',
                nextState,
            })
        })
	}, [])
	
	const { menu = [] } = props.savedMenu || {};
	const { closeMenu } = props;

	return (
		<Container id = "main-menu" style={{maxHeight: "calc(90vh - 100px)", overflow: "auto"}}>
			<Row>
				<Col>
					<Menu 
						closeMenu = { closeMenu }
						addMenu = { addMenu }
						removeMenu = { removeMenu }
						menu = { menu }
						/>
				</Col>
			</Row>
			<Row>
				<Col>
					<Row>
						<Col>
							<Button color="link">
								<Link onClick={() => closeMenu()} className = "menu-link" to = { "/r/popular" }>/Popular Subreddit</Link>
							</Button>
						</Col>
						<Col>
							<Button color="link">
								<Link onClick={() => closeMenu()} className = "menu-link" to = { "/r/all" }>/All Subreddit</Link>
							</Button>
						</Col>
					</Row>
				</Col>
				<Col>
					<form id = "add-menu">
						{/*removed ref here, will need to refix todo*/}
						<input id="add-input" ref={textInput} type="text" />
						<button onClick = { e => 
							{
								e.preventDefault();
								addMenu(textInput.current.value);
							}
						} 
						type="submit">
							Add to Saved Subreddits
						</button>
					</form>			
				</Col>
			</Row>
		</Container>
	)
}

export default connect(({ui}) => ({savedMenu: ui?.savedMenu}))(SavedMenu);