import React from 'react';
import { Switch, Route } from 'react-router';

import { verifySizes, localStorageSet, localStorageGet } from '../utility';


import Reddit from "../containers/Reddit";
import Footer from "../components/Footer";
import TOS from "./Terms";
import LoadingBar from './LoadingBar';

class App extends React.Component{
	constructor(props){
		super(props);

		let twoColCommentsOnly = [33, 67];
		let twoColDetailsOnly = [33, 67];
		let threeCol = [25, 50, 25];

		let twoColCommentsOnlyStorage = localStorageGet("twoColCommentsOnly");
		if(twoColCommentsOnlyStorage && verifySizes(2, twoColCommentsOnlyStorage)){
			twoColCommentsOnly = twoColCommentsOnlyStorage
		}

		let twoColDetailsOnlyStorage = localStorageGet("twoColDetailsOnly");
		if(twoColDetailsOnlyStorage && verifySizes(2, twoColDetailsOnlyStorage)){
			twoColDetailsOnly = twoColDetailsOnlyStorage
		}

		let threeColStorage = localStorageGet("threeCol");
		if(threeColStorage && verifySizes(3, threeColStorage)){
			threeCol = threeColStorage
		}

		let showColumn = 'none';
		let storageShowColumn = localStorageGet('showColumn');
		if(storageShowColumn !== null){
			showColumn = storageShowColumn;
		}

		let hideThumbnails = false;
		let storagehideThumbnails = localStorageGet('hideThumbnails');
		if(storagehideThumbnails !== null){
			hideThumbnails = storagehideThumbnails;
		}

		let hideArrows = false;
		let storageHideArrows = localStorageGet('hideArrows');
		if(storageHideArrows !== null){
			hideArrows = storageHideArrows;
		}

		this.state = {
			showColumn,
			hideThumbnails,
			threeCol,
			twoColCommentsOnly,
			twoColDetailsOnly,
			mobile: false,
			listScrollPercent: 0,
			commentsScrollPercent: 0,
			hideArrows
		}
	}

	static getDerivedStateFromError(error) {
		console.log(error);
        //return {error};
    }

	setListScrollPercent = listScrollPercent => this.setState({listScrollPercent})

	setCommentsScrollPercent = commentsScrollPercent => this.setState({commentsScrollPercent})

	setColSizes = sizes => {
		this.setState(prevState => {
			switch(prevState.showColumn){
				case "none":
					if(verifySizes(3, sizes)){
						localStorageSet('threeCol', sizes);
						return { threeCol: sizes }
					}
					break;
				case "comments":
					if(verifySizes(2, sizes)){
						localStorageSet('twoColCommentsOnly', sizes);
						return { twoColCommentsOnly: sizes }
					}
					break;
				case "details":
					if(verifySizes(2, sizes)){
						localStorageSet('twoColDetailsOnly', sizes);
						return { twoColDetailsOnly: sizes}
					}
					break;
				default:
					return;
			}
		});
	}

	setShowColumn = showColumn => {
		this.setState({showColumn})
		localStorageSet('showColumn', showColumn);
	}

	setHideThumbnails = hideThumbnails => {
		this.setState({hideThumbnails})
		localStorageSet('hideThumbnails', hideThumbnails);
	}

	setHideArrows = hideArrows => {
		this.setState({hideArrows});
		localStorageSet('hideArrows', hideArrows);
	}

	//passing keys to subreddit because we need to re-render in order to keep hooks on
	render(){
		const { showColumn, threeCol, mobile, hideThumbnails, twoColCommentsOnly, twoColDetailsOnly, hideArrows } = this.state;
		const subreddit = < Reddit 
			showColumn={showColumn} 
			setShowColumn={this.setShowColumn}
			twoColCommentsOnly={twoColCommentsOnly}
			twoColDetailsOnly={twoColDetailsOnly}
			threeCol={threeCol}
			onDragEnd={this.onDragEnd} 
			listScrollPercent = {this.state.listScrollPercent}
			commentsScrollPercent = {this.state.commentsScrollPercent}
			setListScrollPercent = {this.setListScrollPercent}
			setCommentsScrollPercent = {this.setCommentsScrollPercent}
			setColSizes = {this.setColSizes}
			hideThumbnails = { hideThumbnails }
			hideArrows  = { hideArrows }
		/>
		return <div id='container' className={mobile ? "mobile" : ''}>
			<LoadingBar />
			<Switch>
				<Route path = "/" exact render = {() => subreddit}/> 
				<Route path = "/r/:subreddit/:link?/:more?" render = {() => subreddit}/>
				<Route path = "/tos" component = { TOS } />
			</ Switch>
			<Footer 
				setHideThumbnails = {this.setHideThumbnails} 
				hideThumbnails = {hideThumbnails} 
				setShowColumn = {this.setShowColumn}
				showColumn = {showColumn}
				enableDetailsOnly={() => this.setShowColumn({showColumn: 'details'})} 
				disableDetailsOnly={() => this.setShowColumn({showColumn: 'none'})}
				hideArrows = {hideArrows}
				setHideArrows = {this.setHideArrows}
				/>
		</div>
	}
}

export default App;