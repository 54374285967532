//@ts-check
import { matchPath } from 'react-router';
import ApiRequests from '../middleware/ApiRequests';

const rootParams = {
	subreddit: "popular",
	comments: ""
}

class RouteInput {
	dispatch;
	getState;
	constructor (Api){
		this.Api = Api;
	}

	middleware = store => {
		this.dispatch = store.dispatch;
		this.getState = store.getState;
		return next => action => {
			next(action)
			switch ( action.type ) {
			case "@@router/LOCATION_CHANGE":
				this.changeLocation ( action.payload.location );
				break;
			case "@@router/CALL_HISTORY_METHOD":
				this.changeLocation ({
					pathname: action.payload.args[0]
				}) 
				break;
			default:
				break;
			}
		}
	}

	changeLocation = payload => {
		this.routes().some( route => {
			let matchedRoute = matchPath( payload.pathname, route.pattern );
			if ( matchedRoute ) route.load ( matchedRoute, this.uid);
			return matchedRoute;
		})
	}

	routes = () => {
		return [{
			pattern: {
				path: '/',
				exact: true
			},
			load: () => {
				return Promise.resolve()
					.then(() => { 
						this.dispatch(ApiRequests.getSubreddit(rootParams.subreddit, this.getState().data.subreddit.sortBy, true, this.getState().data.comments.sortBy))
					})
					.catch( err => {
						console.error(err);
					})
			}
		},
		{
			pattern: {
				path: '/r/:subreddit',
				exact: true,
			},
			//would be better to have chained load not in router, but cannot do sequential load without rearchitecture
			load: ({ params }, uid ) => {
				if ( this.getState().data.subreddit.subreddit === params.subreddit ) {
					return;
				}
				
				return Promise.resolve()
					.then(() => {
						this.dispatch(ApiRequests.getSubreddit(params.subreddit, this.getState().data.subreddit.sortBy, true, this.getState().data.comments.sortBy));
					})
					.catch( err => {
						console.error(err);
					})
			}
		},
		{
			pattern: {
				path: '/r/:subreddit/:comment',
			},
			load: ({ params }, uid ) => {
				return Promise.resolve()
					.then(() => {
						//check if loading from same subreddit, so don't need to reload subreddit
						if ( this.getState().data.subreddit.subreddit === params.subreddit ) {
							this.dispatch(ApiRequests.getComments(params.subreddit, params.comment, this.getState().data.comments.sortBy))
						} else  {
							this.dispatch(ApiRequests.getSubredditComments(params.subreddit, params.comment, this.getState().data.subreddit.sortBy , this.getState().data.comments.sortBy))
						}
					})
					.catch( err => {
						console.error(err);
					})
			}
		}]
	}
}

export default RouteInput;